import React, { useMemo, useState } from 'react';
import { Upload } from '../../apis/Upload';
import ImageAvatar from '../ImageAvatar';
import { allowedImageTypes } from 'core/constants';

const maxSize = 1000 * 1024; // file size (1 MB = 1000 * 1024 bytes)

const ProfileImageInput = ({ image, onChange }: any) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const acceptedTypes = useMemo(() => allowedImageTypes.join(','), []);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Reset states
    setError(null);

    // Validate file type
    if (!allowedImageTypes.includes(file.type)) {
      setError('Please select a valid image file (JPG, PNG, or JPEG).');
      return;
    }

    if (file.size > maxSize) {
      setError('Image size should not exceed 200 KB.');
      return;
    }

    setUploadProgress(0);

    const data = await Upload.uploadImage(file, setUploadProgress);
    if (data) {
      onChange(data);
    }

    setUploadProgress(0);
  };

  const handleImageRemove = () => {
    onChange(null);
  };

  return (
    <div className="rounded-[10px]">
      <div className="text-center block">
        <div className="mb-4 flex justify-center">
          <ImageAvatar image={image} onRemove={handleImageRemove} size={100} />
        </div>
        {uploadProgress > 0 && (
          <div className="mt-4 mb-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="mt-2 text-sm text-gray-600">Uploading: {uploadProgress}%</p>
          </div>
        )}
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        {!image && (
          <div>
            <p className="mb-4 text-[#8297AE] text-[13px]">
              Upload a square sized photo for best result & Maximum image size limit should be 200
              KB (JPG, PNG, JPEG).
            </p>
            <div className="relative">
              <input
                className="w-[150px] h-[60px] opacity-0 z-[9999] absolute right-0 left-0 mx-auto cursor-pointer"
                name="profile-image"
                id="profile-image-input"
                type="file"
                accept={acceptedTypes}
                onChange={handleUpload}
              />
              <label
                htmlFor="profile-image-input"
                className="file-upload-btn text-white bg-[#5F6FFF] rounded-[8px] py-[12px] pl-[20px] pr-[16px] inline-flex items-center justify-center gap-[8px] mb-4 text-[14px] font-medium border-[1px] border-[#5F6FFF] border-solid cursor-pointer"
              >
                <img width="10" src="/onboarding/download-icon.svg" alt="Upload Icon" />
                Upload Image
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileImageInput;
