import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { addMessage } from 'store/reducers/chatSlice';
import { chatStream } from 'apis/eventSource';
import { getAgentTypeText, getFilePath } from 'core/helpers';
import { toastApiError } from 'apis/api';

import Answer from './Answer';
import ChatInput from './ChatInput';
import { Analyzing } from './Analyzing';
import TopBar from 'components/Header/TopBar';
import Question from './Question';
import ReferenceItem from './ReferenceItem';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

import './style.scss';

const SERVER_ERROR = 'SERVER_ERROR';
const NEUWARK_CLOSE = 'NEUWARK_CLOSE';
const OBSERVATION_STREAM_PREFIX = 'OBSERVATION_STREAM_PREFIX';

const DEFAULT_MESSAGE_DATA = {
  question: '',
  answer: '',
  ingests: null,
  sources: null,
  observations: []
};

function MessageNew({ projectUid, project, messageDetails, setMessageDetails }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [answerResponse, setAnswerResponse] = useState<null | any[]>(null);
  const [messageData, setMessageData] = useState<any>(DEFAULT_MESSAGE_DATA);
  const [isLoading, setIsLoading] = useState(false);

  const references = useMemo(() => {
    const ingests = messageData.ingests || messageDetails?.ingests || [];
    const sources = messageData.sources || messageDetails?.message?.extra_data?.sources || [];

    if (ingests?.length && sources?.length) {
      const referenceList = sources.reduce((preState: any[], source: any) => {
        const ingestSource = ingests.find((ingest: any) => ingest?.id === source.ingest_id);
        if (ingestSource) {
          preState.push({ ...source, ingest: ingestSource });
        }
        return preState;
      }, []);
      return referenceList;
    }
    return [];
  }, [messageData.ingests, messageData.sources, messageDetails]);

  useEffect(() => {
    // This is to fix the message loading issue on click on the project in the side bar after a message.
    const newMessageData = { ...DEFAULT_MESSAGE_DATA };
    if (isLoading) {
      newMessageData.question = messageData.question;
    }
    setMessageData(newMessageData);
  }, [messageDetails]);

  const handleSubmit = async (query: string) => {
    if (!projectUid) return;

    setAnswerResponse([]);
    setMessageData({ ...DEFAULT_MESSAGE_DATA, question: query });
    setMessageDetails(null);

    const handleDataEvent = (data: string) => {
      if (data === '') {
        setAnswerResponse((answer: any) => [...answer, '\n']);
      } else if (data.startsWith(OBSERVATION_STREAM_PREFIX)) {
        data = data.slice(OBSERVATION_STREAM_PREFIX.length + 1);
        setMessageData((prev: any) => ({ ...prev, observations: [...prev.observations, data] }));
      } else {
        setAnswerResponse((answer: any) => [...answer, data]);
      }
    };

    const handleErrorEvent = (data: string) => {
      if (data.startsWith(SERVER_ERROR)) {
        const errorMessage = data.slice(SERVER_ERROR.length + 1);
        toast.error(errorMessage);
        setAnswerResponse([]);
        setIsLoading(false);
      }
    };

    function onConnection() {
      // Do something if required
      setIsLoading(false);
    }

    function onMessage(event: any) {
      if (event.event === 'data') {
        handleDataEvent(event.data);
      } else if (event.event === 'event') {
        handleErrorEvent(event.data);
      }
    }
    function onClose() {
      setIsLoading(false);

      setAnswerResponse((answer: any) => [...answer, NEUWARK_CLOSE]);
    }

    setIsLoading(true);

    try {
      const payload = { question: query, stream: true };
      await chatStream(projectUid, payload, onConnection, onMessage, onClose);
    } catch (error) {
      setIsLoading(false);
      toastApiError(error as any, 'Something went wrong');
    }
  };

  useEffect(() => {
    if (answerResponse && answerResponse[answerResponse.length - 1] === NEUWARK_CLOSE) {
      try {
        answerResponse.pop();
        const source = JSON.parse(answerResponse.pop());

        const answer = answerResponse
          .join('')
          .trim()
          .replace(/\n\n\n/g, '\n\n');

        setMessageData({
          ...messageData,
          answer: answer
        });

        const messageId = source?.id;

        const payload = { projectUid, id: messageId, question: messageData.question };
        dispatch(addMessage(payload));

        setAnswerResponse(null);

        navigate(`/agents/${projectUid}/chat/${messageId}`);
      } catch (error) {
        setAnswerResponse(null);
      }
    }
  }, [answerResponse]);

  let answer = '';
  let messageQuestion = '';
  let messageObservations = [];

  if (messageDetails?.message) {
    answer = messageDetails.message.answer;
    messageQuestion = messageDetails.message.question;
    messageObservations = messageDetails.message.extra_data?.observations || [];
  } else {
    answer = answerResponse || messageData.answer;
    messageQuestion = messageData.question;
    messageObservations = messageData.observations;
  }

  return (
    <div className="chat__message_wrapper relative">
      <div className="h-[90%] overflow-y-scroll overflow-x-auto custom-scroll">
        <div className="absolute top-0 left-0 right-0">
          <TopBar />
          {/* <TopHeader projectUid={projectUid} projectName={project?.name} /> */}
        </div>
        <div className="px-20">
          <SpinnerLoader loading={isLoading} icon_size={45}>
            <MessageBody
              question={messageQuestion}
              answer={answer}
              project={project}
              observations={messageObservations}
              references={references}
            />
          </SpinnerLoader>
        </div>
        <div className="fixed bottom-[0px] left-[296px] right-0 px-10 overflow-hidden bg-[#121821] max-w-4xl mx-auto">
          <ChatInput onSubmit={handleSubmit} />
          {/* <Search query={messageDetails?.message?.question || ''} onSubmit={handleSubmit} /> */}
        </div>
      </div>
    </div>
  );
}

export default MessageNew;

const MessageBody = ({ question, answer, project, observations, references }: any) => {
  if (!answer) {
    return (
      <div className="flex items-center pt-44 max-w-4xl mx-auto">
        <div className="rounded-lg p-8 pb-1">
          <div className="mb-4">
            <img src={getFilePath(project?.image?.file_path)} width={30} />
          </div>
          <h2 className="text-2xl font-semibold mb-2 inline-block relative">
            {project?.name}
            <span className="text-[#FCFDFD] text-[11px] bg-[#222831] py-1 px-[10px] rounded-[26px] absolute top-[-10px] right-[-110px] h-[20px] flex items-center justify-center">
              {getAgentTypeText(project?.type)}
            </span>
          </h2>
          <p className="text-gray-400 pr-40 pb-1">{project?.description}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="message-container pt-16 flex justify-between">
      <div className="overflow-scroll pr-4 message-content-container scrollbar-none max-w-3xl mx-auto">
        <Question question={question} />
        <Analyzing observations={observations} />

        <Answer answer={answer} project={project} />
        <div className="mt-8 mb-4">
          {references.map((reference: any) => {
            return (
              <ReferenceItem
                key={`reference-${reference.ingest_id}`}
                ingest={reference.ingest}
                description={reference.text}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
