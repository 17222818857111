import React, { useEffect, useMemo, useState } from 'react';

const DEFAULT_OBSERVATION_ITEM = 1;

export const Analyzing = ({ observations }: any) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const visibleItems = useMemo(() => {
    if (!observations) {
      return [];
    }
    if (isActive) {
      return [...observations];
    }
    return observations.slice(0, DEFAULT_OBSERVATION_ITEM);
  }, [isActive, observations]);

  useEffect(() => {
    if (isActive && observations.length <= 0) {
      setIsActive(false);
    }
  }, [isActive, observations]);

  return (
    <div className="border border-[#222831] bg-[#0A0D11] rounded-[10px] mb-8">
      <div className="bg-[#191F28] py-[10px] px-[18px] flex items-center justify-between">
        <h4>{"Agent's Working Process"}</h4>
        <div className="flex items-center gap-2">
          <span
            className={`cursor-pointer arrow-icon ${isActive ? 'active' : ''}`}
            onClick={handleClick}
          >
            <img src="/message/down-arrow.png" className="mt-1" width="16" alt="" />
          </span>
        </div>
      </div>
      <div className="p-5">
        <ul>
          {visibleItems?.map((data: string, idx: number) => {
            return (
              <li className="flex gap-2 mb-3 items-start" key={`message-obj-${idx}`}>
                <img src="/icons/analyzer-icon.svg" className="mt-1" width="16" alt="" />
                <p>{data}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
