import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootStateType } from 'store/store';
import { useQuery } from 'core/hooks';
import { CustomerChatAPI } from 'apis/customerChat';

import { MessageUserItem } from './MessageUserItem';

import './MessageSidebar.scss';
import { DEFAULT_LIMIT } from 'core/constants';

function MessageSidebar() {
  const { projectUid, chatWidgetId } = useParams();

  const dispatch = useDispatch();

  const query = useQuery();
  const activeCustomerId = query.get('cId');

  const customers = useSelector((state: RootStateType) => state.customerChat.customers);
  const currentChatWidget = useSelector((state: RootStateType) => state.customerChat.chatWidget);

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (projectUid && chatWidgetId && activeCustomerId) {
      CustomerChatAPI.getMessages(dispatch, projectUid, chatWidgetId, activeCustomerId, {
        limit: DEFAULT_LIMIT
      });
    }
  }, [projectUid, chatWidgetId, activeCustomerId]);

  useEffect(() => {
    if (currentChatWidget) {
      setIsChecked(currentChatWidget.is_globally_manual_message === true);
    }
  }, [currentChatWidget]);

  const handleToggle = async () => {
    if (!currentChatWidget || !projectUid) return;

    const isCheckedNew = !isChecked;

    const chatWidgetId = currentChatWidget.id;

    const payload = {
      is_globally_manual_message: isCheckedNew
    };
    const data = await CustomerChatAPI.toggleGlobalManualMessage(projectUid, chatWidgetId, payload);

    if (data) {
      setIsChecked(isCheckedNew);
    }
  };

  return (
    <div className="flex flex-col text-gray-100">
      {/* <div className="p-4 border-b border-gray-700">
        <div className="relative">
          <img
            src="/message/result-search-icon.svg"
            alt="search-icon"
            className="absolute left-2 top-2.5 h-4 w-4 text-gray-500"
          />
          <input
            type="text"
            placeholder="Search conversations"
            className="w-full pl-8 pr-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm text-gray-100"
          />
        </div>
      </div> */}
      <div className="p-3 border-b border-gray-700 flex justify-between">
        <div className="relative text-sm">AiDesk</div>
        <div className="flex items-center gap-[8px] toggle-container">
          <label
            className="toggle-label text-[#F6F7F9] text-[14px]"
            id="toggle-label"
            htmlFor="switcher"
          >
            Manual
          </label>
          <div className="switch-btn">
            <input
              type="checkbox"
              id="switcher"
              className="check"
              checked={isChecked}
              onChange={handleToggle}
            />
            <b className="switch-circle switch"></b>
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-800">
        <div className="divide-y divide-gray-700 pb-[55px]">
          {customers.map((customer) => {
            return (
              <MessageUserItem
                key={`customer-list-${customer.id}`}
                customer={customer}
                isActiveCustomer={activeCustomerId == JSON.stringify(customer.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MessageSidebar;
