import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

type IFormInput = {
  name: string;
  key: string;
};

export default function CommonKey({ handleKeySubmit, keyType, keyTypeName }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const payload = {
      key_type: keyType,
      name: data.name,
      key: data.key
    };

    handleKeySubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            Name
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('name', { required: true })}
          />
        </div>
        {errors.name && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            {keyTypeName}
          </label>
        </div>
        <div className="relative">
          <textarea
            rows={3}
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('key', { required: true })}
          />
        </div>
        {errors.key && <span className="text-red-400">This field is required</span>}
      </div>

      <input type="submit" className="submitBtn w-full mt-8 cursor-pointer" />
    </form>
  );
}
