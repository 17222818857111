import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ReaderEnum, ingestConfig } from 'core/helpers/ingest';

import IngestUploadBtn from '../IngestUploadBtn';

type IFormInput = {
  name: string;
  text: string;
};

export default function IngestText({ handleOnSubmit, onClose }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const payload = {
      reader_type: ReaderEnum.TEXT,
      text: {
        name: data.name,
        text: data.text
      },
      config: ingestConfig
    };

    handleOnSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">Text</div>
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">Name</label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('name', { required: true })}
          />
        </div>
        {errors.name && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">Name</label>
        </div>
        <div className="relative">
          <textarea
            rows={5}
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('text', { required: true })}
          />
        </div>
        {errors.text && <span className="text-red-400">This field is required</span>}
      </div>

      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
