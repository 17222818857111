import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  isOpen: boolean;
  onClose: any;
  children: any;
  className?: string;
}

function ModalRenderForm({ isOpen, onClose, children, className }: Props) {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'bg-[#222831] w-full common-scrollbar-thin max-w-lg mx-auto rounded-lg shadow-xl',
        className
      )}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-[#191f28] p-8 overflow-scroll">
        <div className="cursor-pointer float-left mr-5" onClick={onClose}>
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.351854 8.8644C0.126562 8.63904 0 8.33343 0 8.01477C0 7.69611 0.126562 7.3905 0.351854 7.16514L7.15011 0.366878C7.26097 0.252099 7.39357 0.160548 7.54019 0.0975657C7.68681 0.0345836 7.8445 0.00143196 8.00407 4.53735e-05C8.16363 -0.00134122 8.32188 0.0290652 8.46957 0.0894899C8.61726 0.149915 8.75144 0.239148 8.86427 0.351983C8.97711 0.464817 9.06634 0.598994 9.12676 0.746684C9.18719 0.894373 9.21759 1.05262 9.21621 1.21219C9.21482 1.37175 9.18167 1.52944 9.11869 1.67606C9.05571 1.82268 8.96415 1.95529 8.84938 2.06614L2.90075 8.01477L8.84938 13.9634C9.06828 14.19 9.18941 14.4936 9.18667 14.8087C9.18394 15.1238 9.05755 15.4252 8.83474 15.648C8.61192 15.8708 8.31051 15.9972 7.99542 16C7.68033 16.0027 7.37676 15.8816 7.15011 15.6627L0.351854 8.8644Z"
              fill="#4E637B"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
}

export default ModalRenderForm;
