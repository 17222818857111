import { API_URLS, buildUrl } from 'core/endpoints';

import { API, toastApiError } from './api';

export class ChatAPI {
  static async getMessages(projectUid: string, queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.messages, { projectUid }, queryParams);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async createMessages(projectUid: string, data: any) {
    try {
      const url = buildUrl(API_URLS.messages, { projectUid });
      const res = await API.post(url, data);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async getMessageDetails(projectUid: string, messageId: number) {
    try {
      const url = buildUrl(API_URLS.messageDetails, { projectUid, messageId });
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
