import React from 'react';

import Users from './Users/Users';

function Collaborator() {
  return (
    <div className="w-[920px]">
      <Users />
    </div>
  );
}

export default Collaborator;
