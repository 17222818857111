import PDFPreviewModal from 'components/Utility/PDFViewerModal';
import { extractIngest } from 'core/helpers/ingest';
import React, { useState } from 'react';

const IngestFiles = ({ ingests }: { ingests: any[] }) => {
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

  if (!ingests) return null;

  return (
    <ul className="neu-ingest">
      {ingests.map((ingest, idx) => {
        const { fileExt, ingestName, ingestFullPath, readerType, ingestIcon } =
          extractIngest(ingest);

        if (!readerType) {
          return null;
        }

        return (
          <li key={`ingest-file-${idx}`}>
            {fileExt === 'pdf' ? (
              <PDFPreviewModal
                fileName={ingestName}
                fileUrl={ingestFullPath}
                isOpen={isPDFModalOpen}
                onClick={() => setIsPDFModalOpen(!isPDFModalOpen)}
              >
                <span className="neu-file-link" onClick={() => setIsPDFModalOpen(!isPDFModalOpen)}>
                  <div>
                    <img src={ingestIcon} />
                  </div>
                  {ingestName}
                </span>
              </PDFPreviewModal>
            ) : (
              <a href={ingestFullPath} className="neu-file-link">
                <div>
                  <img src={ingestIcon} />
                </div>
                {ingestName}
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default IngestFiles;
