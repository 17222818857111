import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from 'core/hooks';
import { queryBuilder } from 'core/helpers';

const PROFILETABS = {
  ACCOUNT_INFORMATION: 'account-information',
  PACKAGE_INFORMATION: 'package-infromation',
  SECRET_KEYS: 'secret-keys'
};

function ProfileSidebar() {
  const navigate = useNavigate();
  const query = useQuery();

  const activeTab = query.get('tab') || PROFILETABS.ACCOUNT_INFORMATION;

  const setActiveTab = (tab: string) => {
    navigate(
      {
        pathname: window.location.pathname,
        search: `?${queryBuilder({ tab })}`
      },
      { replace: true }
    );
  };

  const getTabClassName = (tab: string) => {
    if (tab === activeTab) {
      return 'text-gray-100 bg-[#222831] text-[16px] text-left font-normal outline-none block py-6 px-7 border-b-2 border-[#222831] border-solid w-full flex items-center gap-4';
    }
    return 'text-[#4E637B] text-[16px] text-left font-normal outline-none block py-6 px-7 border-b-2 border-[#222831] border-solid w-full flex items-center gap-4';
  };

  const getActiveFillColor = (tab: string) => {
    if (tab === activeTab) {
      return '#ffffff';
    }
    return '#4E637B';
  };

  return (
    <div role="tablist" className="w-full" aria-orientation="horizontal">
      <button
        className={getTabClassName(PROFILETABS.ACCOUNT_INFORMATION)}
        onClick={() => setActiveTab(PROFILETABS.ACCOUNT_INFORMATION)}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 9.5C8.9 9.5 8 9.15 7.3 8.45C6.6 7.75 6.25 6.85 6.25 5.75C6.25 4.65 6.6 3.75 7.3 3.05C8 2.35 8.9 2 10 2C11.1 2 12 2.35 12.7 3.05C13.4 3.75 13.75 4.65 13.75 5.75C13.75 6.85 13.4 7.75 12.7 8.45C12 9.15 11.1 9.5 10 9.5ZM2 17.525V15.175C2 14.5416 2.15834 14 2.475 13.55C2.79167 13.1 3.2 12.7583 3.7 12.525C4.81665 12.025 5.8875 11.65 6.9125 11.4C7.9375 11.15 8.96665 11.025 10 11.025C11.0334 11.025 12.0583 11.1542 13.075 11.4125C14.0916 11.6709 15.1577 12.0433 16.273 12.5298C16.7947 12.7653 17.2129 13.1066 17.5278 13.554C17.8426 14.0013 18 14.5416 18 15.175V17.525H2ZM3.5 16.025H16.5V15.175C16.5 14.9083 16.4208 14.6542 16.2625 14.4125C16.1041 14.1709 15.9083 13.9917 15.675 13.875C14.6083 13.3584 13.6334 13.0041 12.75 12.8125C11.8667 12.6208 10.95 12.525 10 12.525C9.05 12.525 8.125 12.6208 7.225 12.8125C6.325 13.0041 5.35 13.3584 4.3 13.875C4.06665 13.9917 3.875 14.1709 3.725 14.4125C3.575 14.6542 3.5 14.9083 3.5 15.175V16.025ZM10 8C10.65 8 11.1875 7.7875 11.6125 7.3625C12.0375 6.9375 12.25 6.4 12.25 5.75C12.25 5.1 12.0375 4.5625 11.6125 4.1375C11.1875 3.7125 10.65 3.5 10 3.5C9.35 3.5 8.8125 3.7125 8.3875 4.1375C7.9625 4.5625 7.75 5.1 7.75 5.75C7.75 6.4 7.9625 6.9375 8.3875 7.3625C8.8125 7.7875 9.35 8 10 8Z"
            fill={getActiveFillColor(PROFILETABS.ACCOUNT_INFORMATION)}
          />
        </svg>
        Account Information
      </button>
      <button
        className={getTabClassName(PROFILETABS.PACKAGE_INFORMATION)}
        onClick={() => setActiveTab(PROFILETABS.PACKAGE_INFORMATION)}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.77778 8.48889L10 7.37778L12.2222 8.48889V3.33333H7.77778V8.48889ZM5.55556 14.4444V12.6667H10V14.4444H5.55556ZM3.33333 18C2.97778 18 2.66667 17.8667 2.4 17.6C2.13333 17.3333 2 17.0222 2 16.6667V3.33333C2 2.97778 2.13333 2.66667 2.4 2.4C2.66667 2.13333 2.97778 2 3.33333 2H16.6667C17.0222 2 17.3333 2.13333 17.6 2.4C17.8667 2.66667 18 2.97778 18 3.33333V16.6667C18 17.0222 17.8667 17.3333 17.6 17.6C17.3333 17.8667 17.0222 18 16.6667 18H3.33333ZM3.33333 16.6667H16.6667V3.33333H13.5556V10.6444L10 8.86667L6.44444 10.6444V3.33333H3.33333V16.6667Z"
            fill={getActiveFillColor(PROFILETABS.PACKAGE_INFORMATION)}
          />
        </svg>
        Package Information
      </button>

      <button
        className={getTabClassName(PROFILETABS.SECRET_KEYS)}
        onClick={() => setActiveTab(PROFILETABS.SECRET_KEYS)}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill={getActiveFillColor(PROFILETABS.SECRET_KEYS)}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.9434 7.05053L14.6374 8.74458C14.9238 9.02531 15.3822 9.02531 15.6686 8.74458L17.2153 7.19782C17.496 6.91142 17.496 6.45311 17.2153 6.1667L15.5212 4.47266"
            stroke="#4E637B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.9943 3L9.92358 10.0708"
            stroke="#4E637B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 12.9437C3 16.0622 6.37579 18.0112 9.07642 16.4519C10.3298 15.7283 11.1019 14.391 11.1019 12.9437C11.1019 9.82527 7.7261 7.87625 5.02547 9.43551C3.77209 10.1591 3 11.4965 3 12.9437Z"
            stroke="#4E637B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        API Key
      </button>
    </div>
  );
}

export default ProfileSidebar;
