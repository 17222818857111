import React from 'react';
import { MessageItem } from './MessageItem';
import MessageTopBar from './MessageTopBar';
import { IChatWidget } from 'types/customerChat';

interface IProps {
  chatWidgetDetails: IChatWidget;
}

function MessageCard({ chatWidgetDetails }: IProps) {
  return (
    <>
      <MessageTopBar project={chatWidgetDetails.project} />
      <MessageItem />
    </>
  );
}

export default MessageCard;
