import { configureStore } from '@reduxjs/toolkit';

import agentsReducer from './reducers/agentsSlice';
import chatReducer from './reducers/chatSlice';
import loginReducer from './reducers/loginSlice';
import customerChatReducer from './reducers/customerChatSlice';
import localReducer from './reducers/localSlice';

const store = configureStore({
  reducer: {
    login: loginReducer,
    agent: agentsReducer,
    chat: chatReducer,
    customerChat: customerChatReducer,
    local: localReducer
  }
});

export type RootStateType = ReturnType<typeof store.getState>;

export default store;
