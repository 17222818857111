import './Modal.css';

import React from 'react';

export const Modal = ({ children }: any) => {
  return (
    <div className="w-[600px]">
      <div className="bg-[#222831] text-center p-12 rounded-xl">{children}</div>
    </div>
  );
};
