import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  agents: [],
  aiAgents: [],
  searchAgents: [],
  agentDetails: null,
  agentUsers: [],
  agentUserCursor: null,
  allTools: [],
  allToolsCursor: null
};

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAgents: (state, action) => {
      state.agents = action.payload;
    },
    setAIAgents: (state, action) => {
      state.aiAgents = action.payload;
    },
    setSearchAgents: (state, action) => {
      state.searchAgents = action.payload;
    },
    setAgentsDetails: (state, action) => {
      state.agentDetails = action.payload;
    },
    setAgentUsers: (state, action) => {
      const cursor = action.payload?.after;
      const results = action.payload?.results || [];

      if (state.agentUserCursor === cursor) {
        // This is a redundant call nothing need to be updated
        return;
      }

      const newAgentUsers = [...state.agentUsers, ...results];

      state.agentUserCursor = cursor;
      state.agentUsers = newAgentUsers as any;
    },
    setAgentsTools: (state, action) => {
      state.allTools = action.payload.results;
      state.allToolsCursor = action.payload.after;
    }
  }
});

export const {
  setAgents,
  setAgentsDetails,
  setAgentUsers,
  setAgentsTools,
  setAIAgents,
  setSearchAgents
} = agentsSlice.actions;

export default agentsSlice.reducer;
