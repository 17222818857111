import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Agents } from '../../apis/Agents';
import { Layout } from '../../components/Layout/Layout';
import Collaborator from '../../components/Settings/Collaborator';
import Information from '../../components/Settings/Information';
import IngestBody from '../../components/Settings/Ingest/IngestBody';
import { getFilePath, queryBuilder } from '../../core/helpers';
import { useQuery } from 'core/hooks';
import Tools from 'components/Settings/Tools';
import { useDispatch, useSelector } from 'react-redux';
import ChatWidget from 'components/Settings/ChatWidget';

const TABS = {
  BASIC: 'BASIC',
  COLLABORATOR: 'COLLABORATOR',
  INGEST: 'INGEST',
  STATISTICS: 'STATISTICS',
  TOOLS: 'TOOLS',
  CHAT_WIDGET: 'CHAT_WIDGET'
};

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { projectUid } = useParams();
  const agent = useSelector((state: any) => state.agent?.agentDetails);

  const query = useQuery();
  const activeTab = query.get('tab') || TABS.BASIC;

  useEffect(() => {
    if (projectUid) {
      Agents.getAgentsDetails(dispatch, projectUid);
    }
  }, [projectUid]);

  if (!agent) return null;

  const setActiveTab = (tab: string) => {
    navigate(
      {
        pathname: window.location.pathname,
        search: `?${queryBuilder({ tab })}`
      },
      { replace: true }
    );
  };

  const renderTabBody = () => {
    if (activeTab === TABS.BASIC) {
      return <Information agent={agent} />;
    } else if (activeTab === TABS.COLLABORATOR) {
      return <Collaborator />;
    } else if (activeTab === TABS.INGEST) {
      return <IngestBody />;
    } else if (activeTab === TABS.TOOLS) {
      return <Tools />;
    } else if (activeTab === TABS.CHAT_WIDGET) {
      return <ChatWidget />;
    } else if (activeTab === TABS.STATISTICS) {
      return <div>No data</div>;
    } else {
      return <div>Not Implemented</div>;
    }
  };

  const getTabClassName = (tab: string) => {
    if (tab === activeTab) {
      return 'text-[#F6F7F9] font-semibold pb-2 border-b-2 border-[#5FBCFF] border-solid mr-9';
    }
    return 'text-[#4E637B] text-[16px] font-normal outline-none mr-9';
  };

  return (
    <Layout>
      <div className="">
        <div className="profileHeaer border-b-[1px] border-solid border-[#222831] px-[30px] py-[14px] flex items-center gap-[12px]">
          <img src={getFilePath(agent.image?.file_path)} alt="agent" className="w-12 h-12" />
          <h3 className="text-[16px] font-semibold uppercase">settings - {agent.name}</h3>
        </div>
        <div className="p-[30px]">
          <div role="tablist" aria-orientation="horizontal">
            <button
              className={getTabClassName(TABS.BASIC)}
              onClick={() => setActiveTab(TABS.BASIC)}
            >
              Basic Information
            </button>
            <button
              className={getTabClassName(TABS.COLLABORATOR)}
              onClick={() => setActiveTab(TABS.COLLABORATOR)}
            >
              Agent Users
            </button>

            {/* {agent.type == AgentEnum.RAG.toString() ? (
              <button
                className={getTabClassName(TABS.INGEST)}
                onClick={() => setActiveTab(TABS.INGEST)}
              >
                Ingest
              </button>
            ) : (
              <button
                className={getTabClassName(TABS.TOOLS)}
                onClick={() => setActiveTab(TABS.TOOLS)}
              >
                Tools
              </button>
            )} */}

            <button
              className={getTabClassName(TABS.INGEST)}
              onClick={() => setActiveTab(TABS.INGEST)}
            >
              Knowledge Hub
            </button>
            <button
              className={getTabClassName(TABS.CHAT_WIDGET)}
              onClick={() => setActiveTab(TABS.CHAT_WIDGET)}
            >
              Chat Bot Integration
            </button>
          </div>

          <div className="pt-10">{renderTabBody()}</div>
        </div>
      </div>
    </Layout>
  );
}

export default Settings;
