import { ManyChatPlatformType } from 'core/constants';
import { ICustomerUserObj } from 'store/reducers/customerChatSlice';

export function extractCustomerData(customer: ICustomerUserObj) {
  const platformType = customer.platform;
  const manyChatPlatformType = customer.extra_data?.manychat_data?.platform_type;

  let logoPath = '';

  switch (manyChatPlatformType) {
    case ManyChatPlatformType.instagram:
      logoPath = '/public-brand-logo/instagram.svg';
      break;
    case ManyChatPlatformType.messenger:
      logoPath = '/public-brand-logo/messenger.svg';
      break;
    case ManyChatPlatformType.telegram:
      logoPath = '/public-brand-logo/telegram.svg';
      break;
    case ManyChatPlatformType.whatsapp:
      logoPath = '/public-brand-logo/whatsapp.svg';
      break;
    default:
      logoPath = '/message/customer-chat-icon.svg';
      break;
  }

  return { platformType, manyChatPlatformType, logoPath };
}
