import React from 'react';
import ChatWidgetItem from './ChatWidgetItem';
import { IChatWidget } from 'types/customerChat';

const ChatWidgetsDropdown = ({ chatWidgets }: { chatWidgets: IChatWidget[] }) => {
  return (
    <div className="bg-[#222831] rounded-lg px-6 pt-3 pb-0 absolute top-full right-[-4px] mt-3">
      <h3 className="text-[#F6F7F9] text-[20px] font-semibold mb-3">Chats</h3>
      <div className="bg-[#323C48] flex items-center pl-3 rounded-lg mb-5">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 8L1 10M10 5C10 6.06087 9.57857 7.07828 8.82843 7.82843C8.07828 8.57857 7.06087 9 6 9C4.93913 9 3.92172 8.57857 3.17157 7.82843C2.42143 7.07828 2 6.06087 2 5C2 3.93913 2.42143 2.92172 3.17157 2.17157C3.92172 1.42143 4.93913 1 6 1C7.06087 1 8.07828 1.42143 8.82843 2.17157C9.57857 2.92172 10 3.93913 10 5Z"
            stroke="#637B94"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <input
          type="text"
          placeholder="Search"
          className="flex-1 px-4 py-2 text-white bg-transparent focus:outline-none"
        />
      </div>
      <div className="h-[314px] overflow-y-scroll overflow-x-auto">
        {chatWidgets.map((chatWidget) => {
          return <ChatWidgetItem key={chatWidget.id} chatWidget={chatWidget} />;
        })}
      </div>
    </div>
  );
};

export default ChatWidgetsDropdown;
