import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  profile: null, // Add profile state
  tokens: null,
  subscription: {},
  secretKeys: []
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      // Add setProfile reducer
      state.profile = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setSecretKeys: (state, action) => {
      state.secretKeys = action.payload;
    }
  }
});

export const { setProfile, setSubscription, setSecretKeys } = loginSlice.actions;
export default loginSlice.reducer;
