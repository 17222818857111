import { API_URLS, buildUrl } from 'core/endpoints';
import { API, toastApiError } from './api';

export class Ingest {
  static async fileUpload(file: any, projectUid: any) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const url = buildUrl(API_URLS.ingestFile, { projectUid });

      const res = await API.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong while uploading ingest. Please try again.');
      return null;
    }
  }

  static async getPageUrls(projectUid: any, queryParams: any = {}) {
    const url = buildUrl(API_URLS.getUrls, { projectUid }, queryParams);

    try {
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async upload(projectUid: any, payload: any) {
    try {
      const url = buildUrl(API_URLS.ingestUpload, { projectUid });
      const res = await API.post(url, payload);

      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong while uploading ingest. Please try again.');
      return null;
    }
  }

  static async fileList(projectUid: any, queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.ingests, { projectUid }, queryParams);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async reIngest(projectUid: any, ingestId: any) {
    try {
      const url = buildUrl(API_URLS.ingestDetails, { projectUid, ingestId });
      const res = await API.put(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async deleteFile(projectUid: any, ingestId: any) {
    try {
      const url = buildUrl(API_URLS.ingestDetails, { projectUid, ingestId });
      const res = await API.delete(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
