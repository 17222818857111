import React from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useDebounce = (time: number, callback: any) => {
  const ref: any = React.useRef();

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = React.useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, time);
  }, [time]);

  return debouncedCallback;
};
