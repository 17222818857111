import './AddUserModal.scss';

import React, { useState } from 'react';

import { Agents } from '../../apis/Agents';
import CustomModal from '../Modal/CustomModal';

const DEFAULT_VALUE = {
  email: '',
  permission_type: ''
};

export const PERMISSION_TYPE = {
  BASIC: 'BASIC',
  MODERATOR: 'MODERATOR'
};

interface IProps {
  projectUid: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function AddUserModal({ isOpen, onClose, projectUid }: IProps) {
  const [formData, setFormData] = useState(DEFAULT_VALUE);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (!formData.email || !formData.permission_type) {
      alert('Email or User type is required.');
      return;
    }

    const data = await Agents.addAgentUser(projectUid as string, formData);
    if (data) {
      onClose();
    }
  }

  return (
    <CustomModal title="Add User or Collaborator" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-8">
          <label className="pb-2 text-[13px] block font-normal mb-2 leading-4">
            User type
            <span className="text-[12px]"> (User must sign up before adding as collaborator)</span>
          </label>
          <div className="user-type flex items-center gap-[30px]">
            <label className="single-type flex items-center gap-[8px]">
              <input
                type="radio"
                name="permission_type"
                value={PERMISSION_TYPE.BASIC}
                onChange={handleChange}
              />
              <span className="text-[#4E637B] text-base font-normal">General User</span>
            </label>
            <label className="single-type flex items-center gap-[8px]">
              <input
                type="radio"
                name="permission_type"
                value={PERMISSION_TYPE.MODERATOR}
                onChange={handleChange}
              />
              <span className="text-[#4E637B] text-base font-normal">Moderator</span>
            </label>
          </div>
        </div>
        <div className="form-group mb-6">
          <label className="text-[13px] block font-normal mb-2 leading-4" htmlFor="agent-name">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="agent-name"
            className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
            placeholder="Enter email address"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <div className="button-area flex items-center justify-center gap-[16px]">
            <button
              className="btn btn-item go-back-btn text-base font-semibold text-center p-5 rounded-[12px] basis-1/2 bg-[#323C48] text-[#8297AE]"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button className="flex items-center justify-center gap-[8px] btn btn-item create-btn text-base font-semibold text-center p-5 rounded-[12px] basis-1/2 bg-[#5F6FFF] text-white">
              Confirm
              <img width="14" src="/icons/check-icon.svg" alt="Arrow Icon" />
            </button>
          </div>
        </div>
      </form>
    </CustomModal>
  );
}
