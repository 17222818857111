import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ReaderEnum, ingestConfig } from 'core/helpers/ingest';

import IngestUploadBtn from '../IngestUploadBtn';
import { toast } from 'react-toastify';

type IFormInput = {
  secret_id: string;
  name: string;
  base_url: string;
  space_key: string;
  page_ids: string[];
  link: string;
};

export default function IngestConfluence({ handleOnSubmit, onClose, secretKeys }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IFormInput>();

  useEffect(() => {
    if (secretKeys?.length) {
      setValue('secret_id', secretKeys[0].id, { shouldDirty: true });
    }
  }, [secretKeys]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!data.space_key && !data.page_ids) {
      toast.error('Space key or Page Id is required');
      return;
    }

    const payload = {
      reader_type: ReaderEnum.CONFLUENCE,
      secret_id: data.secret_id,
      confluence: {
        name: data.name,
        base_url: data.base_url,
        space_key: data.space_key,
        page_ids: [data.page_ids],
        link: data.link
      },
      config: ingestConfig
    };

    handleOnSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">Confluence Page</div>
      </div>
      <div className="mb-4">
        <label className="text-[13px] block font-normal mb-2 leading-4">
          Confluence Secret Key
        </label>
        <select
          {...register('secret_id', { required: true })}
          className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
        >
          {secretKeys.map((secretKey: any) => {
            return (
              <option
                key={`secret-key-option-${secretKey.id}`}
                value={secretKey.id}
                className="custom-option"
              >
                {secretKey.name}
              </option>
            );
          })}
        </select>
        {errors.secret_id && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">Name</label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('name', { required: true })}
          />
        </div>
        {errors.name && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Base URL
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('base_url', { required: true })}
          />
        </div>
        {errors.base_url && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Space Key
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('space_key')}
          />
        </div>
        {errors.space_key && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Page ID
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('page_ids')}
            placeholder="e.g., 123456"
          />
        </div>
        {errors.page_ids && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Confluence Link
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px] text-white Input"
            {...register('link', { required: true })}
            type="url"
          />
        </div>
        {errors.link && <span className="text-red-400">This field is required</span>}
      </div>

      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
