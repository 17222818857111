import React, { useEffect } from 'react';
import { Layout } from '../../components/Layout/Layout';
import './Message.scss';
import MessageCard from 'components/MessageCard/MessageCard';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerChatAPI } from 'apis/customerChat';
import { useParams } from 'react-router-dom';
import { RootStateType } from 'store/store';
import { DEFAULT_LIMIT } from 'core/constants';

const Message = () => {
  const { projectUid, chatWidgetId } = useParams();

  const dispatch = useDispatch();

  const chatWidgetDetails = useSelector((state: RootStateType) => state.customerChat.chatWidget);

  useEffect(() => {
    if (projectUid && chatWidgetId) {
      CustomerChatAPI.getChatWidgetDetails(dispatch, projectUid, chatWidgetId);
      const queryParams = {
        limit: DEFAULT_LIMIT
      };
      CustomerChatAPI.getCustomers(dispatch, projectUid, chatWidgetId, queryParams);
    }
  }, [projectUid, chatWidgetId]);

  if (!chatWidgetDetails || chatWidgetDetails.id != Number(chatWidgetId)) return null;

  return (
    <Layout>
      <div className="message__wrapper">
        <div className="h-lvh	overflow-y-scroll overflow-x-auto pt-24 flex flex-col-reverse custom-scroll">
          <MessageCard chatWidgetDetails={chatWidgetDetails} />
        </div>
      </div>
    </Layout>
  );
};

export default Message;
