import { API, toastApiError } from 'apis/api';
import { API_URLS, buildUrl } from 'core/endpoints';
import { toast } from 'react-toastify';

export class UserProfile {
  static async get() {
    try {
      const url = buildUrl(API_URLS.profile);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong while fetching the profile. Please try again.');
      return null;
    }
  }

  static async userInfo() {
    try {
      const url = buildUrl(API_URLS.userInfo);
      const res = await API.get(url);

      return res.data;
    } catch (error) {
      toastApiError(
        error,
        'Something went wrong while fetching user information. Please try again.'
      );
      return null;
    }
  }

  static async update(profileData: any) {
    try {
      const url = buildUrl(API_URLS.profile);
      const res = await API.put(url, profileData);
      toast.success('Profile updated successfully');

      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong while updating the profile. Please try again.');
      return null;
    }
  }

  static async createUserSecrets(payload: any) {
    try {
      const url = buildUrl(API_URLS.secretKeys);
      const res = await API.post(url, payload);
      toast.success('Secret created successfully');

      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong while creating user secrets. Please try again.');
      return null;
    }
  }

  static async getUserSecrets(queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.secretKeys, null, queryParams);
      const res = await API.get(url);

      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong to load secrets. Please try again.');
      return null;
    }
  }

  static async deleteUserSecrets(secretId: number) {
    try {
      const url = buildUrl(API_URLS.secretKeyDetails, { secretId });
      const res = await API.delete(url);
      toast.success('Secret deleted successfully');

      return res.data;
    } catch (error) {
      toastApiError(error, 'Something went wrong while deleting user secrets. Please try again.');
      return null;
    }
  }
}
