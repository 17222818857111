import React, { useEffect, useState } from 'react';

import { UserProfile } from '../../apis/Profile';

import ProfileImageInput from './ProfileImageInput';
import CustomModal from 'components/Modal/CustomModal';

const DEFAULT_USER_FORM: any = {
  full_name: '',
  image: null
};

function UserInfoModal({ isOpen, onClose, profile }: any) {
  const [formData, setFormData] = useState(DEFAULT_USER_FORM);
  const [errors, setErrors] = useState(DEFAULT_USER_FORM);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (profile != null) {
      const { full_name, image } = profile;
      setFormData({ full_name, image });
    }
  }, [profile]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formErrors: any = {};
    let hasError = false;
    const { full_name, image } = formData;

    if (!full_name.trim()) {
      formErrors.full_name = 'Full Name is required';
      hasError = true;
    }

    if (hasError) {
      setErrors(formErrors);
      return;
    }

    const image_id = image?.id || null;

    const payload = { full_name, image_id };

    const data = await UserProfile.update(payload);

    if (data !== null) {
      onClose(true);
      window.location.reload();
    }
  };

  const handleImageChange = (image: any) => {
    setFormData({ ...formData, image: image });
  };

  if (profile == null) {
    return null;
  }

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} title="Edit Profile">
      <div>
        <form onSubmit={handleSubmit}>
          <ProfileImageInput image={formData.image} onChange={handleImageChange} />
          <div className="mb-4">
            <div className="flex flex-start">
              <label htmlFor="full_name" className="Manrope text-sm font-semibold mb-1 InputLabel ">
                Full Name:
              </label>
            </div>
            <input
              value={formData.full_name}
              onChange={handleChange}
              type="text"
              id="full_name"
              name="full_name"
              className="w-full bg-slate-700 mt-[5px] text-white Input"
            />
            <div className="flex flex-start">
              {errors.full_name && (
                <div className="text-red-500 text-sm mt-1">{errors.full_name}</div>
              )}
            </div>
          </div>

          <button type="submit" className="submitBtn w-full mt-8">
            Save Profile
          </button>
        </form>
      </div>
    </CustomModal>
  );
}

export default UserInfoModal;
