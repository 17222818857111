import React, { useState, Suspense, lazy, useEffect } from 'react';

// Lazy load the PDF viewer components
const LazyPDFViewer = lazy(() =>
  import('@llamaindex/pdf-viewer').then((module) => ({ default: module.PDFViewer }))
);
const LazyPdfFocusProvider = lazy(() =>
  import('@llamaindex/pdf-viewer').then((module) => ({ default: module.PdfFocusProvider }))
);

import './style.scss';

function PDFPreviewModal({ fileName, fileUrl, isOpen, onClick, children }: any) {
  const file = {
    id: fileName,
    url: fileUrl
  };
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null; // Return null on server-side
  }

  return (
    <>
      {children}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="absolute inset-0 flex">
            <div className="w-1/12 backdrop-blur-[1px]"></div>
            <div className="w-10/12 h-full flex flex-col bg-white">
              <div className="flex justify-end p-2">
                <button
                  onClick={() => onClick()}
                  className="text-gray-600 hover:text-gray-800 transition-colors"
                  aria-label="Close PDF preview"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-grow overflow-hidden">
                <Suspense
                  fallback={
                    <div className="flex items-center justify-center h-full">
                      Loading PDF viewer...
                    </div>
                  }
                >
                  <LazyPdfFocusProvider>
                    <LazyPDFViewer file={file} containerClassName="pdf-preview-container" />
                  </LazyPdfFocusProvider>
                </Suspense>
              </div>
            </div>
            <div className="w-1/12 backdrop-blur-[1px]"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default PDFPreviewModal;
