import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

interface IProps {
  onSubmit: (v: string) => void;
}

function ChatInput({ onSubmit }: IProps) {
  const [newQuery, setNewQuery] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newQuery?.length) {
      setNewQuery('');
      await onSubmit(newQuery);
      setNewQuery('');
    } else {
      toast.warn('Nothing to ask');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as any);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 7 * 24)}px`;
    }
  }, [newQuery]);

  return (
    <form className="relative mb-4" onSubmit={handleSubmit}>
      <textarea
        ref={textareaRef}
        value={newQuery}
        onChange={(e) => setNewQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Ask Question..."
        className="w-full bg-[#191F28] rounded-lg pl-4 pr-12 py-6 resize-none min-h-[60px] custom-scroll focus:outline-none"
        rows={1}
      />
      <button type="submit" className="absolute right-2 bottom-4 p-2" disabled={!newQuery.trim()}>
        <img width="36" src="/message/chat-message.svg" alt="Icon" />
      </button>
    </form>
  );
}

export default ChatInput;
