import { API_URLS, buildUrl } from 'core/endpoints';
import { API, BaseAxios, toastApiError } from './api';

export class Auth {
  static async registerUser(registerUserData: any) {
    try {
      const url = buildUrl(API_URLS.registration);
      const res = await BaseAxios.post(url, registerUserData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async loginUser(loginUserData: any) {
    try {
      const url = buildUrl(API_URLS.login);
      const res = await BaseAxios.post(url, loginUserData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async googleLogin(googleLoginData: any) {
    try {
      const url = buildUrl(API_URLS.googleLogin);
      const res = await BaseAxios.post(url, googleLoginData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async forgotPassword(payload: any) {
    try {
      const url = buildUrl(API_URLS.forgotPasswordRequest);
      const res = await BaseAxios.post(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async resetPassword(payload: any) {
    try {
      const url = buildUrl(API_URLS.forgotPasswordReset);
      const res = await BaseAxios.post(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async changePassword(payload: any) {
    try {
      const url = buildUrl(API_URLS.changePassword);
      const res = await API.post(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
