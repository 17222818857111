import store from 'store/store';

import { IWebSocketEventData } from '../types';
import {
  addMessage,
  editMessage,
  deleteMessage,
  updateCustomerUser
} from 'store/reducers/customerChatSlice';

export class CustomerChatResolver {
  static createMessage(eventData: IWebSocketEventData) {
    store.dispatch(addMessage(eventData.payload));
  }
  static editMessage(eventData: IWebSocketEventData) {
    store.dispatch(editMessage(eventData.payload));
  }
  static deleteMessage(eventData: IWebSocketEventData) {
    store.dispatch(deleteMessage(eventData.payload));
  }
  static updateCustomerUser(eventData: IWebSocketEventData) {
    store.dispatch(updateCustomerUser(eventData.payload));
  }
}
