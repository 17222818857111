import React from 'react';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface IProps {
  codeString: string;
  language: string;
}

const CodeBlock = ({ codeString, language }: IProps) => {
  const copyCode = () => {
    navigator.clipboard.writeText(codeString).then(() => {
      // Do something if required
    });
  };

  return (
    <div className="relative bg-gray-800 text-white p-4 rounded-lg">
      <button
        className="absolute top-2 right-2 bg-gray-700 hover:bg-gray-600 text-sm text-white py-1 px-2 rounded focus:outline-none focus:ring-2 focus:ring-gray-500"
        onClick={copyCode}
      >
        Copy
      </button>
      <SyntaxHighlighter
        language={language}
        style={oneDark}
        customStyle={{ padding: 0, background: 'transparent' }}
        wrapLongLines={true}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
