import SpinnerLoader from 'components/Utility/SpinnerLoader';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from 'store/store';

const IngestUploadBtn = ({ onClose, isDisabled = false }: any) => {
  const isUploadingIngest = useSelector((state: RootStateType) => state.local.isUploadingIngest);

  const handleClose = (e) => {
    e.preventDefault();
    onClose(false);
  };

  return (
    <div className="form-group mt-8">
      <div className="button-area flex items-center justify-center gap-[16px]">
        <button
          onClick={handleClose}
          className="btn btn-item go-back-btn text-base font-semibold text-center p-5 rounded-[12px] basis-1/2 bg-[#323C48] text-[#8297AE]"
        >
          Cancel
        </button>
        <SpinnerLoader loading={isUploadingIngest} icon_size={45} className="basis-1/2">
          <button
            disabled={isDisabled}
            className="flex items-center justify-center gap-[8px] btn btn-item create-btn text-base font-semibold text-center p-5 rounded-[12px] w-full bg-[#5F6FFF] text-white"
          >
            Ingest Now
            <img width="14" src="/icons/chevron-right.svg" alt="Arrow Icon" />
          </button>
        </SpinnerLoader>
      </div>
    </div>
  );
};

export default IngestUploadBtn;
