import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MessageNew from '../../components/Chat/MessageNew/MessageNew';
import { Layout } from '../../components/Layout/Layout';
import { ChatAPI } from '../../apis/Chat';
import { setMessages } from '../../store/reducers/chatSlice';
import { MESSAGE_LIMIT } from 'core/constants';
import { Agents } from 'apis/Agents';

function ChatNew() {
  const { projectUid, messageId } = useParams();
  const dispatch = useDispatch();

  const [messageDetails, setMessageDetails] = useState(null);

  const messages = useSelector((state: any) => projectUid && state.chat[projectUid]?.messages);
  const agentDetails = useSelector((state: any) => state.agent?.agentDetails);

  async function loadMessages(projectUid: string, limit: number) {
    const data = await ChatAPI.getMessages(projectUid, { limit });
    if (data) {
      data.projectUid = projectUid;
      dispatch(setMessages(data));
    }
  }

  async function loadMessageDetails(projectUid: string, messageId: number) {
    const data = await ChatAPI.getMessageDetails(projectUid, messageId);
    if (data) {
      setMessageDetails(data);
    }
  }

  useEffect(() => {
    if (projectUid) {
      Agents.getAgentsDetails(dispatch, projectUid);
      loadMessages(projectUid, MESSAGE_LIMIT);
    }
  }, [projectUid]);

  useEffect(() => {
    if (projectUid && messageId) {
      loadMessageDetails(projectUid, parseInt(messageId));
    } else {
      setMessageDetails(null);
    }
  }, [projectUid, messageId]);

  return (
    <Layout messages={messages} showMessages={true}>
      {projectUid && (
        <MessageNew
          projectUid={projectUid}
          messageDetails={messageDetails}
          project={agentDetails}
          setMessageDetails={setMessageDetails}
        />
      )}
    </Layout>
  );
}

export default ChatNew;
