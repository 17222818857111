import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  onBack: any;
  children: any;
  className?: string;
  headerName: string;
}

function ModalSecretForm({ onBack, children, className, headerName }: Props) {
  return (
    <div
      className={twMerge(
        'bg-[#222831] w-full common-scrollbar-thin max-w-lg mx-auto rounded-lg shadow-xl',
        className
      )}
    >
      <div className="absolute top-0 left-0 right-0 bottom-1 bg-[#191f28] p-8">
        <div className="pb-3">
          <div className="cursor-pointer float-left mr-2 p-3" onClick={onBack}>
            <img src="/icons/back-icon.svg" />
          </div>
          <div className="flex items-center gap-3 p-3">
            <div className="-mt-1">{headerName}</div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default ModalSecretForm;
