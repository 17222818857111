import { getFilePath } from 'core/helpers';
import React from 'react';
import { Link } from 'react-router-dom';
import { IChatWidget } from 'types/customerChat';

const ChatWidgetItem = ({ chatWidget }: { chatWidget: IChatWidget }) => {
  const chatWidgetId = chatWidget.id;
  const projectUid = chatWidget.project.uid;

  return (
    <Link
      to={`/agents/${projectUid}/chat-widget/${chatWidgetId}/customer-chats`}
      className="flex items-center gap-4 pb-6"
    >
      <img src={getFilePath(chatWidget.project.image?.file_path)} width="32" height="32" alt="" />
      <div>
        <h4 className="text-white text-[14px] mb-[2px]">{chatWidget.name}</h4>
        <p className="text-[11px]">
          {chatWidget.has_unread_message ? (
            <span className="text-white relative before:content-[attr(before)] before:absolute before:right-[-10px] before:top-[6px] before:bg-white before:w-1 before:h-1 before:rounded-[50px]">
              new messages
            </span>
          ) : (
            <span className="text-[#637B94] relative before:content-[attr(before)] before:absolute before:right-[-10px] before:top-[6px] before:bg-[#4E637B] before:w-1 before:h-1 before:rounded-[50px]">
              No new message
            </span>
          )}
          {/* <span className="text-[#637B94] pl-5">{msgTime}</span> */}
        </p>
      </div>
    </Link>
  );
};

export default ChatWidgetItem;
