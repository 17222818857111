import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ChangePasswordModal from '../ChangePasswordModal';
import ImageAvatar from '../ImageAvatar';

import UserInfoModal from './UserInfoModal';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

function AccountInformation() {
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);

  const profile = useSelector((state: any) => state.login.profile);

  return (
    <SpinnerLoader loading={!profile} icon_size={50}>
      <div className="p-4">
        <div className="w-[720px]">
          <div className="mb-4">
            <ImageAvatar
              image={profile?.image as any}
              size={70}
              full_name={profile?.full_name}
              fontSize={'2xl'}
              showCrossIcon={false}
            />
          </div>
          <div className="gap-[10px]">
            <div className="mb-3 w-full">
              <label className="block text-[#8297AE] text-[13px]">Name</label>
              <p className="bg-transparent text-[#F6F7F9] text-base py-2 outline-none w-full">
                {profile?.full_name}
              </p>
            </div>
            <div className="mb-3 w-full">
              <label className="block text-[#8297AE] text-[13px]">Email</label>
              <p className="bg-transparent text-[#F6F7F9] text-base py-2 outline-none w-full">
                {profile?.email}
              </p>
            </div>
          </div>
          <div className="flex item-center gap-[16px] mt-8">
            <button
              className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
              onClick={() => setIsChangePasswordOpen(true)}
            >
              <img src="/icons/key-icon.svg" />
              Change Password
            </button>
            <button
              className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
              onClick={() => setIsUserInfoOpen(true)}
            >
              <img src="/icons/edit-icon.svg" />
              Edit information
            </button>
          </div>
        </div>
        <ChangePasswordModal
          isOpen={isChangePasswordOpen}
          onClose={() => setIsChangePasswordOpen(false)}
        />
        <UserInfoModal
          profile={profile}
          isOpen={isUserInfoOpen}
          onClose={() => setIsUserInfoOpen(false)}
        />
      </div>
    </SpinnerLoader>
  );
}

export default AccountInformation;
