import { getStreamHeaders } from 'apis/api';
import { API_URLS, buildUrl } from 'core/endpoints';

const delimiter = ': ';

function splitChunkString(str: string) {
  const index = str.indexOf(delimiter);

  if (index !== -1) {
    const firstPart = str.slice(0, index);
    const secondPart = str.slice(index + delimiter.length);

    return [firstPart, secondPart];
  }
  return ['', ''];
}

export const chatStream = async (
  projectUid: string,
  payload: object,
  onConnection: any,
  onMessage: any,
  onClose: any
) => {
  const headers = await getStreamHeaders();
  const url = buildUrl(API_URLS.messages, { projectUid });

  // getting response from server based on the user prompt
  const response = await fetch(url, {
    method: 'post',
    headers: headers,
    body: JSON.stringify(payload)
  });
  if (!response.ok || !response.body) {
    const data = await response.json();

    const errorObj = { response: { status: response.status, data: data } };

    throw errorObj;
  }

  onConnection();

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  const loopRunner = true;

  while (loopRunner) {
    const { value, done } = await reader.read();
    if (done) break;

    const decodedChunk = decoder.decode(value, { stream: true });

    const events = decodedChunk.split('\n\n');

    events.forEach((eachChunk) => {
      if (eachChunk !== '') {
        const [event, data] = splitChunkString(eachChunk);

        const eventObj = { event, data };

        onMessage(eventObj);
      }
    });
  }

  onClose();
};
