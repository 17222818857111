import React, { useState, useRef, useEffect } from 'react';

/**
    <EllipsisDropdown>
        <ul>
            <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">Option 1</li>
            <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">Option 2</li>
            <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">Option 3</li>
        </ul>
    </EllipsisDropdown>
*/

const EllipsisDropdown = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef: any = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative " ref={dropdownRef}>
      <button onClick={toggleDropdown} className="cursor-pointer p-3">
        <span className="bg-[#22394b] w-[5px] h-[5px] rounded-full block mb-0.5"></span>
        <span className="bg-[#22394b] w-[5px] h-[5px] rounded-full block mb-0.5"></span>
        <span className="bg-[#22394b] w-[5px] h-[5px] rounded-full block"></span>
      </button>
      {isOpen && (
        <div
          className="absolute top-8 w-[200px] -right-0 z-10"
          style={{
            background: 'var(--Neutral-8, #222831)',
            borderRadius: '12px',
            borderBottom: '1px solid #2F3944'
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default EllipsisDropdown;
