import { AZURE_BLOB_HOST, DEFAULT_EXT } from 'core/constants';

const UNITS = ['K', 'M', 'B', 'T'];

export function queryBuilder(queryParams: any) {
  if (!queryParams) {
    return '';
  }
  const params = new URLSearchParams(queryParams);
  return params.toString();
}

export function getFilePath(imagePath: string | null | undefined, defaultImage = '') {
  if (!imagePath) {
    return defaultImage;
  }
  return `${AZURE_BLOB_HOST}/${imagePath}`;
}

export function formatNumberToHumanReadable(num: number) {
  if (!num) return '0';

  if (num < 1000) return num.toString();
  const unitIndex = Math.floor((num.toString().length - 1) / 3) - 1;
  const unitValue = Math.pow(1000, unitIndex + 1);
  const formattedNumber = (num / unitValue).toFixed(1);
  return `${formattedNumber}${UNITS[unitIndex]}`;
}

export function getExtFromFilename(filename: string) {
  if (!filename) return '';

  const allParts = filename.split('.');

  if (allParts?.length > 0) {
    return allParts[allParts.length - 1];
  }
  return DEFAULT_EXT;
}

export function getFilename(filename: string) {
  let name = '';
  let ext = '';

  if (!filename) return { name, ext };

  const allParts = filename.split('.');

  if (allParts?.length > 0) {
    name = allParts[0];
    if (allParts?.length > 1) {
      ext = allParts[allParts.length - 1];
    }
  }
  return { name, ext };
}

const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: userTimeZone
};

export function toHumanReadableDT(utcDateTime: string | null): string {
  if (!utcDateTime) return '';

  const utcDateObject: Date = new Date(utcDateTime + 'Z');

  const humanReadable: string = utcDateObject.toLocaleString('en-US', options);

  return humanReadable;
}

export function toHumanReadableDate(utcDateTime: string | null): string {
  if (!utcDateTime) return '';

  const utcDateObject: Date = new Date(utcDateTime + 'Z');

  // Use toLocaleDateString to get the date only
  const humanReadable: string = utcDateObject.toLocaleDateString('en-US');

  return humanReadable;
}

export function generateUUID4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getUserDisplayName(fullName: string | null) {
  if (!fullName) return null;

  const nameParts = fullName.trim().split(' ');

  if (nameParts.length == 0) return null;

  return nameParts[0];
}

export function getAgentTypeText(agentType: string) {
  if (!agentType) return '';

  if (agentType === 'RAG') {
    return 'Simple Agent';
  } else if (agentType === 'AGENT') {
    return 'Advance Agent';
  } else {
    throw 'Invalid agent type';
  }
}
