import { API_URLS, buildUrl } from 'core/endpoints';
import { API, toastApiError } from './api';

export class Subscription {
  static async getSubscriptions() {
    try {
      const url = buildUrl(API_URLS.subscriptionPlans);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async subscribe(subscribeData: any) {
    try {
      const url = buildUrl(API_URLS.subscribe);
      const res = await API.post(url, subscribeData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async subscriptionDetails(showError = true) {
    try {
      const url = buildUrl(API_URLS.userSubscriptionDetails);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      if (showError) {
        toastApiError(error);
      }
      return null;
    }
  }
  static async createSubscriptionSession(subscribeData: any) {
    try {
      const url = buildUrl(API_URLS.createSubscriptionSession);
      const res = await API.post(url, subscribeData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async verifySubscriptionSession(sessionData: any) {
    try {
      const url = buildUrl(API_URLS.verifySubscriptionSession);
      const res = await API.post(url, sessionData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async cancelSubscription(payload: any) {
    try {
      const url = buildUrl(API_URLS.cancelSubscription);
      const res = await API.post(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
