import { useEffect, useRef } from 'react';

import { WS_URL } from 'core/constants';
import eventHandler from './eventHandler';
import { getAccessTokenThatMinValidForNMinutes } from 'apis/api';

const RECONNECT_DELAY = 1000 * 30; // 30 second delay
const TOKEN_PADDING_MINUTES = 5;

const WebSocketComponent = ({ profile }: { profile: any }) => {
  const ws: any = useRef(null);
  const reconnectInterval: any = useRef(null);

  useEffect(() => {
    if (profile) {
      connectWebSocket();

      return () => {
        // Clean up WebSocket connection on unmount
        if (ws.current) ws.current.close();
        if (reconnectInterval.current) clearInterval(reconnectInterval.current);
      };
    }
  }, [profile]);

  const handleWsEvent = (event: any) => {
    eventHandler(event);
  };

  const connectWebSocket = async () => {
    const accessToken = await getAccessTokenThatMinValidForNMinutes(TOKEN_PADDING_MINUTES);
    ws.current = new WebSocket(`${WS_URL}?token=${accessToken}`);

    ws.current.onopen = () => {
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current); // Stop reconnection attempts
        reconnectInterval.current = null;
      }
    };

    // When a message is received
    ws.current.onmessage = (event: any) => {
      handleWsEvent(event);
    };

    // When WebSocket closes
    ws.current.onclose = () => {
      attemptReconnect(); // Attempt to reconnect on close
    };

    // Handle any WebSocket errors
    ws.current.onerror = () => {
      ws.current.close(); // Ensure the socket is closed in case of error
    };
  };

  // Reconnection logic
  const attemptReconnect = () => {
    if (!reconnectInterval.current) {
      reconnectInterval.current = setInterval(() => {
        connectWebSocket();
      }, RECONNECT_DELAY);
    }
  };

  return null;
};

export default WebSocketComponent;
