import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Agents } from 'apis/Agents';
import { COLLABORATOR_LIMIT } from 'core/constants';
import { setAgentUsers } from 'store/reducers/agentsSlice';
import AddUserModal from '../../AddUser/AddUserModal';
import UsersItem from './UsersItem';

function Users() {
  const { projectUid } = useParams();
  const dispatch = useDispatch();
  const [isUserAddOpen, setIsUserAddOpen] = useState(false);
  // const [hasMoreMessage, setHasMoreMessage] = useState(true);

  const agentUsers = useSelector((state: any) => state.agent.agentUsers);

  async function getUsers(projectUid: string, after: any = null) {
    const queryParams: any = { limit: COLLABORATOR_LIMIT };

    if (after) {
      queryParams['after'] = after;
    }

    const data = await Agents.getAgentUsers(projectUid, queryParams);

    if (data) {
      data.results = data.results.filter((obj: any) => obj.permission_type !== 'ADMIN');
      dispatch(setAgentUsers(data));
    }
  }

  useEffect(() => {
    if (projectUid) {
      getUsers(projectUid);
    }
  }, [projectUid]);

  if (!projectUid) return null;

  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <div>Users</div>
        <button
          className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
          onClick={() => setIsUserAddOpen(true)}
        >
          <img width="16" src="/settings/user-icon.svg" alt="Close icon" />
          Add
        </button>
      </div>
      <div className="p-8 border-[1px] border-solid border-[#222831] rounded-[16px]">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-left text-[#4E637B] text-sm font-medium w-[320px]">Name</th>
              <th className="text-[#4E637B] text-sm font-medium text-left">Permission</th>
              <th className="text-[#4E637B] text-sm font-medium text-left">Added by</th>
              <th className="text-[#4E637B] text-sm font-medium">YY - MM - DD</th>
              <th className="text-[#4E637B] text-sm font-medium">Action</th>
            </tr>
          </thead>
          <tbody>
            {agentUsers.map((userData: any) => {
              const user = userData?.user;
              const creator = userData?.creator;
              return (
                <UsersItem
                  key={`user-permission-${userData.id}`}
                  projectUid={projectUid}
                  permissionId={userData.id}
                  image={user?.image as any}
                  user_name={user?.full_name}
                  permission_type={userData?.permission_type}
                  creator_name={creator?.full_name}
                  date={userData.created_at}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <AddUserModal
        projectUid={projectUid}
        isOpen={isUserAddOpen}
        onClose={() => setIsUserAddOpen(false)}
      />
    </>
  );
}

export default Users;
