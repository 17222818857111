import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ReaderEnum, ingestConfig, parseGoogleDriveFolderUrl } from 'core/helpers/ingest';

import IngestUploadBtn from '../IngestUploadBtn';

type IFormInput = {
  link: string;
};

export default function IngestGoogleDriveFolder({ handleOnSubmit, onClose }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const { folderId } = parseGoogleDriveFolderUrl(data.link);
    if (!folderId) {
      alert('Invalid folder link');
      return null;
    }

    const payload = {
      reader_type: ReaderEnum.GOOGLE_DRIVE,
      google_drive: {
        link: data.link,
        folder_id: folderId
      },
      config: ingestConfig
    };

    handleOnSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">Google Drive</div>
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Google Drive Folder Link{' '}
            <div className="font-extralight">
              Add{' '}
              <span className="font-bold">neuwarktest@neuwark-426910.iam.gserviceaccount.com</span>{' '}
              user to read the folder
            </div>
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('link', { required: true })}
            type="url"
          />
        </div>
        {errors.link && <span className="text-red-400">This field is required</span>}
      </div>
      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
