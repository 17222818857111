import { API_URLS, buildUrl } from 'core/endpoints';
import { API, toastApiError } from './api';

export class Upload {
  static async uploadImage(file: File, onProgress?: (progress: number) => void) {
    const formData = new FormData();
    formData.append('file', file);

    const url = buildUrl(API_URLS.uploadImage);

    try {
      const response = await API.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress?.(percentCompleted);
          }
        }
      });

      return response.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
