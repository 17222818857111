import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { BASE_API_URL, CHAT_WIDGET_SCRIPT_URL } from 'core/constants';
import CodeBlock from 'components/Utility/CodeBlock';
import EllipsisDropdown from 'components/Utility/EllipsisDropdown';
import { CustomerChatAPI } from 'apis/customerChat';

import ChatWidgetForm from './ChatWidgetForm';

function getChatWidgetScript(app_id: string) {
  return `<script
  defer="defer"
  src="${CHAT_WIDGET_SCRIPT_URL}"
  data-app-id="${app_id}"
></script>
`;
}

function getManyChatJsonExample() {
  return `{
  "platform_type": "whatsapp",
  "message_tag": "ACCOUNT_UPDATE",
  "subscriber_id": "{{user_id}}",
  "input": "{{last_input_text}}",
  "full_name": "{{full_name}}",
  "extra_data": []
}
`;
}

export const ChatWidgetCard = ({ projectUid, chatWidget }: any) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleUpdate = async (payload: any) => {
    const data = await CustomerChatAPI.editChatWidgets(projectUid, chatWidget.id, payload);
    if (data != null) {
      window.location.reload();
    }
  };

  const handleDelete = async (chatWidgetId: string) => {
    if (confirm('Are you sure want to delete chat widget') && projectUid) {
      const data = await CustomerChatAPI.deleteChatWidgets(projectUid, chatWidgetId as string);
      if (data != null) {
        window.location.reload();
      }
    }
  };

  return (
    <div className="p-6 w-[768px] rounded-[8px] border-[1px] border-[#2F3944]">
      <div className="flex items-center justify-between mb-[22px]">
        <div>{chatWidget.name}</div>
        <div className="flex justify-between">
          <Link to={`/agents/${projectUid}/chat-widget/${chatWidget.id}/customer-chats`}>
            <div className="bg-[#323C48] rounded-[10px] p-3 cursor-pointer">
              <img width="20" src="/message/message-icon.svg" alt="Icon" />
            </div>
          </Link>
          <EllipsisDropdown>
            <ul>
              <li
                className="hover:bg-gray-600 cursor-pointer"
                style={{
                  borderBottom: '1px solid #2F3944'
                }}
              >
                <div className="flex gap-4 items-center p-[16px]" onClick={() => setIsEdit(true)}>
                  <img className="w-[16px] h-[16px]" src="/icons/edit-icon.svg" alt="" />
                  <button>Edit</button>
                </div>
              </li>
              <li className="hover:bg-gray-600 cursor-pointer">
                <div
                  className="flex gap-4 items-center p-[16px]"
                  onClick={() => handleDelete(chatWidget.id)}
                >
                  <img className="w-[16px] h-[16px]" src="/icons/delete-icon.svg" alt="" />
                  <button>Delete</button>
                </div>
              </li>
            </ul>
          </EllipsisDropdown>
        </div>
      </div>
      <div>Domain: {chatWidget.site_domain}</div>

      <div className="mt-4">
        Description:{' '}
        <span className="mt-[4px] text-sm text-[#8297AE] mb-6 text-ellipsis overflow-hidden">
          {chatWidget.description}
        </span>
        <div className="mt-4">
          <p>Script:</p>
          <CodeBlock language="javascript" codeString={getChatWidgetScript(chatWidget.app_id)} />
        </div>
        <div className="mt-4">
          <p>Manychat Post Request URL:</p>
          <CodeBlock
            language="txt"
            codeString={`${BASE_API_URL}/api/v1/manychat/${chatWidget.app_id}/new_message`}
          />
        </div>
        <div className="mt-4">
          <p>ManyChat JSON Payload:</p>
          <CodeBlock language="json" codeString={getManyChatJsonExample()} />
        </div>
      </div>
      {isEdit && (
        <ChatWidgetForm
          title="Edit Chat Widget"
          isOpen={isEdit}
          onClose={() => setIsEdit(false)}
          handleFormSubmit={handleUpdate}
          defaultValue={chatWidget}
        />
      )}
    </div>
  );
};

export default ChatWidgetCard;
