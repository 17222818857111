import { toast } from 'react-toastify';

import { API, toastApiError } from './api';
import { setAgentsDetails } from 'store/reducers/agentsSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { API_URLS, buildUrl } from 'core/endpoints';

export class Agents {
  static async projectCommonInfo(queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.projectCommonInfo, null, queryParams);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getAgents(queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.projects, null, queryParams);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async createAgents(agentData: any) {
    try {
      const url = buildUrl(API_URLS.projects);
      const res = await API.post(url, agentData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getAgentsDetails(dispatch: Dispatch, projectUid: any) {
    try {
      const url = buildUrl(API_URLS.projectDetails, { projectUid });
      const res = await API.get(url);
      dispatch(setAgentsDetails(res.data));
      return res.data;
    } catch (error) {
      toastApiError(error);
      toast.error('Something wrong to get agent details');
      return null;
    }
  }

  static async update(projectUid: any, payload: any) {
    try {
      const url = buildUrl(API_URLS.projectDetails, { projectUid });
      const res = await API.patch(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async deleteAgents(projectUid: any) {
    try {
      const url = buildUrl(API_URLS.projectDetails, { projectUid });
      const res = await API.delete(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async addAgentUser(projectUid: string, agentData: any) {
    try {
      const url = buildUrl(API_URLS.projectUsers, { projectUid });
      const res = await API.post(url, agentData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getAgentUsers(projectUid: string, queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.projectUsers, { projectUid }, queryParams);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async editAgentUsers(projectUid: string, permissionId: number, payload: any) {
    try {
      const url = buildUrl(API_URLS.projectUsersPermission, { projectUid, permissionId });
      const res = await API.put(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async deleteAgentUsers(projectUid: string, permissionId: number) {
    try {
      const url = buildUrl(API_URLS.projectUsersPermission, { projectUid, permissionId });
      const res = await API.delete(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getAllTools(queryParams: any = {}) {
    try {
      const url = buildUrl(API_URLS.tools, null, queryParams);
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getProjectTools(projectUid: string) {
    try {
      const url = buildUrl(API_URLS.projectTools, { projectUid });
      const res = await API.get(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async updateProjectTools(projectUid: string, payload: any) {
    try {
      const url = buildUrl(API_URLS.projectTools, { projectUid });
      const res = await API.post(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
