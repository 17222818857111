import './AddUserModal.scss';

import React, { useState } from 'react';

import { Agents } from '../../apis/Agents';
import CustomModal from '../Modal/CustomModal';

import { PERMISSION_TYPE } from './AddUserModal';

interface IProps {
  projectUid: string;
  permissionId: number;
  isOpen: boolean;
  permissionType: string;
  onClose: () => void;
}

export default function UpdateAgentUserPermission({
  isOpen,
  onClose,
  projectUid,
  permissionId,
  permissionType
}: IProps) {
  const [selectedOption, setSelectedOption] = useState(permissionType);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    const data = await Agents.editAgentUsers(projectUid as string, permissionId, {
      permission_type: selectedOption
    });
    if (data) {
      onClose();
      window.location.reload();
    }
  }

  return (
    <CustomModal title="Update user permission" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-8">
          <label className="pb-2 text-[13px] block font-normal mb-2 leading-4">User type</label>
          <div className="user-type flex items-center gap-[30px]">
            <label className="single-type flex items-center gap-[8px]">
              <input
                type="radio"
                value={PERMISSION_TYPE.BASIC}
                checked={PERMISSION_TYPE.BASIC === selectedOption}
                onChange={handleChange}
              />
              <span className="text-[#4E637B] text-base font-normal">General User</span>
            </label>
            <label className="single-type flex items-center gap-[8px]">
              <input
                type="radio"
                value={PERMISSION_TYPE.MODERATOR}
                checked={PERMISSION_TYPE.MODERATOR === selectedOption}
                onChange={handleChange}
              />
              <span className="text-[#4E637B] text-base font-normal">Moderator</span>
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="button-area flex items-center justify-center gap-[16px]">
            <button
              className="btn btn-item go-back-btn text-base font-semibold text-center p-5 rounded-[12px] basis-1/2 bg-[#323C48] text-[#8297AE]"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button className="flex items-center justify-center gap-[8px] btn btn-item create-btn text-base font-semibold text-center p-5 rounded-[12px] basis-1/2 bg-[#5F6FFF] text-white">
              Confirm
              <img width="14" src="/icons/check-icon.svg" alt="Arrow Icon" />
            </button>
          </div>
        </div>
      </form>
    </CustomModal>
  );
}
