import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ReaderEnum, ingestConfig } from 'core/helpers/ingest';

import IngestUploadBtn from '../IngestUploadBtn';

type IFormInput = {
  secret_id: string;
  name: string;
  link: string;
  page_id: string;
};

export default function IngestNotion({ handleOnSubmit, onClose, secretKeys }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IFormInput>();

  useEffect(() => {
    if (secretKeys?.length) {
      setValue('secret_id', secretKeys[0].id, { shouldDirty: true });
    }
  }, [secretKeys]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const payload = {
      reader_type: ReaderEnum.NOTION_PAGE,
      secret_id: data.secret_id,
      notion_page: {
        name: data.name,
        link: data.link,
        page_ids: [data.page_id]
      },
      config: ingestConfig
    };
    handleOnSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">Notion Page</div>
      </div>
      <div className="mb-4">
        <label className="text-[13px] block font-normal mb-2 leading-4">Notion Secret Key</label>
        <select
          {...register('secret_id', { required: true })}
          className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
        >
          {secretKeys.map((secretKey: any) => {
            return (
              <option
                key={`secret-key-option-${secretKey.id}`}
                value={secretKey.id}
                className="custom-option"
              >
                {secretKey.name}
              </option>
            );
          })}
        </select>
        {errors.secret_id && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Page Name
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('name', { required: true })}
          />
        </div>
        {errors.name && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Page Link
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('link', { required: true })}
            type="url"
          />
        </div>
        {errors.link && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Page Id
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('page_id', { required: true })}
          />
        </div>
        {errors.page_id && <span className="text-red-400">This field is required</span>}
      </div>
      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
