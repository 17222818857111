import React from 'react';
import { Link } from 'react-router-dom';

const PaymentCanceled = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <h1 className="text-4xl font-bold mb-4">Subscription Process is Failed</h1>
        <p className="text-lg">We are not able to complete the subscription process.</p>
        <Link className="text-blue-400 hover:text-blue-300 mt-4" to="/subscriptions/packages">
          {' '}
          Try again
        </Link>
      </div>
    </div>
  );
};

export default PaymentCanceled;
