import { CustomerChatAPI } from 'apis/customerChat';
import { extractCustomerData } from 'core/helpers/customer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { ICustomerUserObj } from 'store/reducers/customerChatSlice';

const activeConversationClass =
  'bg-gray-800 relative before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-blue-600';

interface MessageUserItemProps {
  customer: ICustomerUserObj;
  isActiveCustomer: boolean;
}

const CUSTOMER_NAME_CHAR_LIMIT = 18;
const LAST_MESSAGE_CHAR_LIMIT = 22;

export const MessageUserItem = ({ customer, isActiveCustomer }: MessageUserItemProps) => {
  const { projectUid, chatWidgetId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  let name = customer.name || customer.full_name || JSON.stringify(customer.id);
  let lastMessage = customer.last_message?.short_message || '';
  const unreadMessageCount = customer.user_unread_message;
  const customerId = customer.id;
  const userUnreadMessage = customer.user_unread_message;

  if (name?.length > CUSTOMER_NAME_CHAR_LIMIT + 2) {
    name = `${name.substring(0, CUSTOMER_NAME_CHAR_LIMIT)}...`;
  }
  if (lastMessage.length > LAST_MESSAGE_CHAR_LIMIT + 2) {
    lastMessage = `${lastMessage.substring(0, LAST_MESSAGE_CHAR_LIMIT)}...`;
  }

  const handleApi = async (customerId: any) => {
    if (userUnreadMessage > 0 && projectUid && chatWidgetId && customerId) {
      const payload = { user_unread_message: 0 };
      CustomerChatAPI.updateCustomerUser(dispatch, projectUid, chatWidgetId, customerId, payload);
    }
  };

  const { logoPath } = extractCustomerData(customer);

  return (
    <Link
      to={`${location.pathname}?cId=${customerId}`}
      onClick={() => handleApi(customerId)}
      className={`flex items-center space-x-3 p-4 hover:bg-gray-800 transition-colors cursor-pointer ${isActiveCustomer ? activeConversationClass : ''}`}
    >
      <div className="flex-shrink-0 w-10 h-10 rounded-lg flex items-center justify-center bg-gray-800">
        <img src={logoPath} alt="customer chat icon" className="w-5 h-5" />
      </div>

      <div className="flex-1 min-w-0 flex justify-between items-center">
        <div>
          <p className="text-sm font-medium text-gray-100 truncate">{name}</p>
          <p className="text-xs text-gray-400 truncate">{lastMessage}</p>
        </div>
        {unreadMessageCount > 0 && (
          <div className="ml-2 bg-blue-600 rounded-full w-5 h-5 flex items-center justify-center">
            <span className="text-[11px] text-white font-medium">{unreadMessageCount}</span>
          </div>
        )}
      </div>
    </Link>
  );
};
