import { createSlice } from '@reduxjs/toolkit';

interface IMessageObject {
  messageCursor: number | null;
  messages: any[];
}
interface IChatStore {
  // messages under agent projectUid
  [key: string]: IMessageObject;
}

const initialState: IChatStore = {};

function getMessageObj() {
  return {
    messages: [],
    messageCursor: null
  };
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state: IChatStore, action) => {
      const projectUid = action.payload.projectUid;
      const cursor = action.payload?.after;
      const results = action.payload?.results;
      // const results = (action.payload?.results || []).reverse();

      if (state[projectUid] == null) {
        state[projectUid] = getMessageObj();
      }

      const message = { ...state[projectUid] };
      if (message.messageCursor === cursor) {
        // This is a redundant call nothing need to be updated
        return;
      }

      message.messageCursor = cursor;
      message.messages = [...message.messages, ...results];

      state[projectUid] = message;
    },
    addMessage: (state: IChatStore, action) => {
      const projectUid = action.payload.projectUid;
      if (state[projectUid] == null) {
        state[projectUid] = getMessageObj();
      }

      const message = { ...state[projectUid] };
      const lastMessage = message.messages[message.messages.length - 1];
      if (lastMessage?.id === action.payload.id) {
        // Duplicate update
        return;
      }

      const payload = {
        id: action.payload?.id,
        question: action.payload?.question
      };

      message.messages = [payload, ...message.messages];

      state[projectUid] = message;
    }
  }
});

export const { setMessages, addMessage } = chatSlice.actions;
export default chatSlice.reducer;
