import React, { useEffect, useState, useRef } from 'react';

import { Layout } from '../../components/Layout/Layout';
import AccountInformation from '../../components/ProfileSettings/AccountInformation';
import PackageInformation from '../../components/ProfileSettings/PackageInformation';
import SecretKey from '../../components/ProfileSettings/SecretKey';
import { useQuery } from 'core/hooks';
import ImageAvatar from 'components/ImageAvatar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const PROFILETABS = {
  ACCOUNT_INFORMATION: 'account-information',
  PACKAGE_INFORMATION: 'package-infromation',
  SECRET_KEYS: 'secret-keys'
};

export default function ProfileSettings() {
  const query = useQuery();
  const [moreSettings, setMoreSettings] = useState(false);
  const getMoreSettingsRef: any = useRef(null);

  const activeTab = query.get('tab') || PROFILETABS.ACCOUNT_INFORMATION;

  const renderTabBody = () => {
    if (activeTab === PROFILETABS.ACCOUNT_INFORMATION) {
      return <AccountInformation />;
    } else if (activeTab === PROFILETABS.PACKAGE_INFORMATION) {
      return <PackageInformation />;
    } else if (activeTab === PROFILETABS.SECRET_KEYS) {
      return <SecretKey />;
    }
  };

  const profile = useSelector((state: any) => state.login.profile);

  const handleMoreSettingsClickOutside = (event) => {
    if (getMoreSettingsRef.current && !getMoreSettingsRef.current.contains(event.target)) {
      setMoreSettings(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMoreSettingsClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleMoreSettingsClickOutside);
    };
  }, []);

  return (
    <Layout>
      <div className="mb-6">
        <div className="flex items-center justify-between profileHeaer border-b-[1px] border-solid border-[#222831] px-[30px] py-[14px] flex items-center gap-[12px]">
          <div className="flex items-center gap-3">
            <img src="/profile-setting/user-icon.svg" />
            <h3 className="text-[16px] font-semibold uppercase">Profile Setting</h3>
          </div>
          <div
            className="border border-[#222831] flex items-center gap-3 rounded-[52px] py-1 pr-1 pl-4 relative cursor-pointer"
            onClick={() => {
              setMoreSettings(!moreSettings);
            }}
            ref={getMoreSettingsRef}
          >
            {profile?.full_name}
            <ImageAvatar
              image={profile?.image as any}
              size={40}
              full_name={profile?.full_name}
              fontSize={'[15px]'}
              showCrossIcon={false}
            />
            {moreSettings && (
              <div
                className="text-[#637B94] absolute z-10 top-full right-0 w-[100%] min-w-[230px] mt-3"
                style={{
                  background: 'var(--Neutral-8, #222831)',
                  borderRadius: '12px',
                  borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
                }}
              >
                <div
                  className="p-[16px]"
                  style={{
                    borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
                  }}
                >
                  <Link
                    to={`/profile-settings`}
                    className="flex gap-4 items-center hover:text-gray-200"
                  >
                    <img className="w-[16px] h-[16px]" src="/icons/add-user.svg" alt="" />
                    <p className="">Profile Setting</p>
                  </Link>
                </div>

                <div className="p-[16px] items-center">
                  <a href={`/logout`} className="flex gap-4 items-center hover:text-gray-200">
                    <img className="w-[16px] h-[16px]" src="/icons/setting.svg" alt="" />
                    <p className="">Logout</p>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="pl-6">{renderTabBody()}</div>
      </div>
    </Layout>
  );
}
