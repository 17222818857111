import { GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '../../apis/Auth';
import { setTokens } from 'core/helpers/token';

const GoogleAuthButton = () => {
  const navigate = useNavigate();

  const handleCredential = async (credentialResponse: any) => {
    const { credential } = credentialResponse;

    const data = await Auth.googleLogin({ token: credential });
    if (data) {
      setTokens(data.access_token, data.refresh_token);
      navigate('/');
    }
  };

  return (
    <div className="flex justify-center mt-4">
      <GoogleLogin
        onSuccess={handleCredential}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
};

export default GoogleAuthButton;
