import './Subscription.css';

import React from 'react';

import { Layout } from '../../components/Layout/Layout';
import { Subscriptions } from '../../components/Subscriptions/Subscriptions';

const Subscription = () => {
  return (
    <Layout>
      <div className="relative">
        <div
          className="overlay"
          style={{
            backgroundImage: `url(/auth/authback.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            position: 'relative',
            top: 0,
            left: 0,
            zIndex: 0
          }}
        >
          <div className="py-12 h-screen" style={{ position: 'relative', zIndex: 1 }}>
            <Subscriptions />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Subscription;
