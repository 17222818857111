import './Login/Login.css';

import { clsx } from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '../apis/Auth';
import Background from '../components/AuthBackground/AuthBackground';
import GoogleAuthButton from '../components/Oauth/GoogleOauth';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

const LoginPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    fullName: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    fullName: '',
    confirmPassword: '',
    passwordUnmatched: ''
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginError, setLoginError] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Add your login logic here
    const formErrors: any = {};
    let hasErrors = false;

    if (!formData.email.trim()) {
      formErrors.email = 'Email is required';
      hasErrors = true;
    }
    if (!formData.fullName.trim()) {
      formErrors.fullName = 'Username is required';
      hasErrors = true;
    }

    if (!formData.password.trim()) {
      formErrors.password = 'Password is required';
      hasErrors = true;
    }
    if (!formData.confirmPassword.trim()) {
      formErrors.confirmPassword = 'Confirm Password is required';
      hasErrors = true;
    }
    if (!hasErrors) {
      if (formData.password.trim() !== formData.confirmPassword.trim()) {
        formErrors.passwordUnmatched = 'Password Unmatched';
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      const payload = {
        email: formData.email.trim().toLowerCase(),
        full_name: formData.fullName.trim(),
        password: formData.password
      };
      setIsLoading(true);
      const data = await Auth.registerUser(payload);
      setIsLoading(false);

      if (data) {
        navigate('/login');
      }
    } else {
      setErrors(formErrors);
      setLoginError(true);
    }
  };
  return (
    <div className="relative">
      <Background imageUrl="/auth/authback.png" />

      <div className="main-container ">
        <div className="mt-[20px] align-center flex justify-center">
          <img width={200} src="/brand-logo/logo.svg" alt="" />
        </div>
        <div className="mt-[20px] align-center flex justify-center">
          <h3 className="AuthHeader Manrope text-white">Create account</h3>
        </div>

        <div className="flex justify-center mt-4">
          <div className="AuthBody flex justify-center">
            <div className="w-[400px]">
              {/* form section */}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="email"
                      className="Manrope text-sm font-semibold mb-1 InputLabel "
                    >
                      Name
                    </label>
                  </div>
                  <input
                    value={formData.fullName}
                    onChange={handleChange}
                    type="text"
                    id="name"
                    name="fullName"
                    className={clsx({
                      'transparent-input w-full border mt-[5px] text-white Input ': true,
                      ErrorInput: loginError
                    })}
                  />
                  <div className="flex flex-start">
                    {errors.fullName && (
                      <div className="text-red-500 text-sm mt-1">{errors.fullName}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="email"
                      className="Manrope text-sm font-semibold mb-1 InputLabel "
                    >
                      Email
                    </label>
                  </div>
                  <input
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    id="email"
                    name="email"
                    className={clsx({
                      'transparent-input w-full border mt-[5px] text-white Input ': true,
                      ErrorInput: loginError
                    })}
                  />
                  <div className="flex flex-start">
                    {errors.email && (
                      <div className="text-red-500 text-sm mt-1">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="password"
                      className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
                    >
                      Password:
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      value={formData.password}
                      onChange={handleChange}
                      type={passwordVisibility ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="w-full transparent-input border mt-[5px]  text-white Input"
                    />
                    <img
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                      className="absolute eyeIcon pointer "
                      src="/auth/Icon.png"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-start">
                    {errors.password && (
                      <div className="text-red-500 text-sm mt-1">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="confirmPassword"
                      className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
                    >
                      Confirm Password:
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      type={passwordVisibility ? 'text' : 'password'}
                      id="confirmPassword"
                      name="confirmPassword"
                      className="w-full transparent-input border mt-[5px]  text-white Input"
                    />
                    <img
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                      className="absolute eyeIcon pointer "
                      src="/auth/Icon.png"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-start">
                    {errors.confirmPassword && (
                      <div className="text-red-500 text-sm mt-1">{errors.confirmPassword}</div>
                    )}
                  </div>
                  {errors.passwordUnmatched && (
                    <div className="flex justify-between mt-4 items-center">
                      <p className="text-red-500 error-text">{errors.passwordUnmatched}</p>
                    </div>
                  )}
                </div>
                <SpinnerLoader loading={isLoading}>
                  <button type="submit" className="submitBtn w-full mt-8">
                    Registration
                  </button>
                </SpinnerLoader>
              </form>
              <div className="flex justify-between mt-4 items-center gap-[10px] ">
                <div className="splitter"></div>
                <p className="continueText">or Continue with</p>
                <div className="splitter"></div>
              </div>

              <GoogleAuthButton />

              {/* <div className="flex justify-between mt-4 items-center gap-[16px]">
                    <div className="socialContainer">
                        <img className="socialIcon" src="/auth/Apple.png" alt="" />
                    </div>
                    <div className="socialContainer">
                        <img className="socialIcon" src="/auth/Google.png" alt="" />
                    </div>
                    <div className="socialContainer">
                      <img className="socialIcon" src="/auth/facebook.png" alt="" />
                    </div>
              </div> */}
              <div className="mt-4 mb-4">
                <p className="createAccountText">
                  Already have an account?{' '}
                  <a href="/login">
                    <span className="loginText">Log In</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*
const GoogleAuthButton = () => {
  const handleGoogleAuth = () => {
    console.log("Google Authentication initiated");
  };

  return (
    <button className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150">
      <img
        className="w-6 h-6"
        src="https://www.svgrepo.com/show/475656/google-color.svg"
        loading="lazy"
        alt="google logo"
      />
      <span className="text-white">Login with Google</span>
    </button>
  );
};
*/

export default LoginPage;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { Auth } from "../apis/Auth";
// import { useNavigate } from 'react-router-dom';
// const RegistrationPage = () => {
//   return (
//     <div className="bg-gray-900 text-white flex justify-center items-center h-screen">
//       <div className="max-w-md w-full p-6 bg-gray-800 rounded-lg shadow-lg">
//         <div className="flex justify-center mt-4">
//           <GoogleAuthButton />
//         </div>
//         <span className="mt-2 mb-2">or</span>
//         <RegisterForm  />
//         <p className="mt-4">
//           Already have an account?
//           <span className="text-blue-400">
//             <Link to="/login"> Login</Link>
//           </span>
//         </p>
//       </div>
//     </div>
//   );
// };

// const RegisterForm = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     email: "",
//     fullName: "",
//     password: ""
//   });

//   const [errors, setErrors] = useState({
//     email: "",
//     fullName: "",
//     password: ""
//   });

//   const handleChange = (e: any) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e: any) => {
//     e.preventDefault();
//     // Validation
//     let formErrors: any = {};
//     let hasErrors = false;

//     if (!formData.email.trim()) {
//       formErrors.username = "Username is required";
//       hasErrors = true;
//     }

//     if (!formData.fullName.trim()) {
//       formErrors.fullName = "Full Name is required";
//       hasErrors = true;
//     }

//     if (!formData.password.trim()) {
//       formErrors.password = "Password is required";
//       hasErrors = true;
//     }

// if (!hasErrors) {
//   Auth.registerUser(
//     {
//       email: formData.email,
//       full_name: formData.fullName,
//       password: formData.password
//     }
//     )
//     .then((res) => {
//       console.log("reg res", res.data);
//       navigate('/login');

//     })
//     .catch((e) => {
//       console.log("err", e.response);
//     });
// }
//     setErrors(formErrors);

//     // Proceed with form submission
//     console.log("Form submitted", formData);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div className="mb-4">
//         <div className="flex flex-start">
//           <label htmlFor="username" className="text-sm font-semibold mb-1">
//             Email:
//           </label>
//         </div>
//         <input
//           type="email"
//           id="email"
//           name="email"
//           className="w-full border rounded-lg px-3 py-2 bg-gray-700 text-white"
//           value={formData.email}
//           onChange={handleChange}
//         />
//         <div className="flex flex-start">
//           {errors.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>}
//         </div>
//       </div>
//       <div className="mb-4">
//         <div className="flex flex-start">
//           <label htmlFor="fullName" className="text-sm font-semibold mb-1">
//             Full Name:
//           </label>
//         </div>
//         <input
//           type="text"
//           id="fullName"
//           name="fullName"
//           className="w-full border rounded-lg px-3 py-2 bg-gray-700 text-white"
//           value={formData.fullName}
//           onChange={handleChange}
//         />
//          <div className="flex flex-start">
//           {errors.fullName && <div className="text-red-500 text-sm mt-1">{errors.fullName}</div>}
//          </div>
//       </div>
//       <div className="mb-4">
//         <div className="flex flex-start">
//           <label
//             htmlFor="password"
//             className="block text-sm font-semibold mb-1"
//           >
//             Password:
//           </label>
//         </div>
//         <input
//           type="password"
//           id="password"
//           name="password"
//           className="w-full border rounded-lg px-3 py-2 bg-gray-700 text-white"
//           value={formData.password}
//           onChange={handleChange}
//         />
//          <div className="flex flex-start">
//           {errors.password && <div className="text-red-500 text-sm mt-1">{errors.password}</div>}
//          </div>
//       </div>
//       <button
//         type="submit"
//         className="bg-blue-600 text-white font-semibold px-4 py-2 rounded-lg hover:bg-blue-700 pl-16 pr-16 mt-2"
//       >
//         Sign Up
//       </button>
//     </form>
//   );
// };

// const GoogleAuthButton = () => {
//   const handleGoogleAuth = () => {
//     console.log("Google Authentication initiated");
//   };

//   return (
//     <button className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150">
//       <img
//         className="w-6 h-6"
//         src="https://www.svgrepo.com/show/475656/google-color.svg"
//         loading="lazy"
//         alt="google logo"
//       />
//       <span className="text-white">SignUp with Google</span>
//     </button>
//   );
// };

// export default RegistrationPage;
