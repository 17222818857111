import React, { useState } from 'react';

import { UserProfile } from 'apis/Profile';
import { SecretKeyEnum, SecretKeyIcons, SecretKeyName } from 'core/constants';

import CustomModal from 'components/Modal/CustomModal';
import CommonKey from './SecretKeyForms/CommonKey';
import ConfluenceKey from './SecretKeyForms/ConfluenceKey';
import ModalSecretForm from './ModalSecretForm';
import BoxKey from './SecretKeyForms/BoxKey';
import MicrosoftSharePoint from './SecretKeyForms/MicrosoftSharePoint';
import MicrosoftOneDrive from './SecretKeyForms/MicrosoftOneDrive';

const SecretOptions = [
  {
    value: SecretKeyEnum.OPENAI,
    name: SecretKeyName[SecretKeyEnum.OPENAI],
    icon: SecretKeyIcons[SecretKeyEnum.OPENAI],
    component: CommonKey
  },
  {
    value: SecretKeyEnum.MISTRAL,
    name: SecretKeyName[SecretKeyEnum.MISTRAL],
    icon: SecretKeyIcons[SecretKeyEnum.MISTRAL],
    component: CommonKey
  },
  {
    value: SecretKeyEnum.COHERE,
    name: SecretKeyName[SecretKeyEnum.COHERE],
    icon: SecretKeyIcons[SecretKeyEnum.COHERE],
    component: CommonKey
  },
  {
    value: SecretKeyEnum.GEMINI,
    name: SecretKeyName[SecretKeyEnum.GEMINI],
    icon: SecretKeyIcons[SecretKeyEnum.GEMINI],
    component: CommonKey
  },
  {
    value: SecretKeyEnum.ANTHROPIC,
    name: SecretKeyName[SecretKeyEnum.ANTHROPIC],
    icon: SecretKeyIcons[SecretKeyEnum.ANTHROPIC],
    component: CommonKey
  },
  // {
  //   value: SecretKeyEnum.SLACK,
  //   name: SecretKeyName[SecretKeyEnum.SLACK],
  //   icon: SecretKeyIcons[SecretKeyEnum.SLACK],
  //   component: CommonKey
  // },
  // {
  //   value: SecretKeyEnum.GITHUB,
  //   name: SecretKeyName[SecretKeyEnum.GITHUB],
  //   icon: '/ingest/github-icon.svg',
  //   component: CommonKey
  // },
  // {
  //   value: SecretKeyEnum.READWISE,
  //   name: SecretKeyName[SecretKeyEnum.READWISE],
  //   icon: '/ingest/readwise-icon.svg',
  //   component: CommonKey
  // },
  {
    value: SecretKeyEnum.NOTION,
    name: SecretKeyName[SecretKeyEnum.NOTION],
    icon: SecretKeyIcons[SecretKeyEnum.NOTION],
    component: CommonKey
  },
  // {
  //   value: SecretKeyEnum.TELEGRAM,
  //   name: SecretKeyName[SecretKeyEnum.TELEGRAM],
  //   icon: SecretKeyIcons[SecretKeyEnum.TELEGRAM],
  //   component: CommonKey
  // },
  {
    value: SecretKeyEnum.CONFLUENCE,
    name: SecretKeyName[SecretKeyEnum.CONFLUENCE],
    icon: SecretKeyIcons[SecretKeyEnum.CONFLUENCE],
    component: ConfluenceKey
  },
  // {
  //   value: SecretKeyEnum.YOUTUBE,
  //   name: SecretKeyName[SecretKeyEnum.YOUTUBE],
  //   icon: '/ingest/youtube-icon.svg',
  //   component: CommonKey
  // },
  {
    value: SecretKeyEnum.BOX,
    name: SecretKeyName[SecretKeyEnum.BOX],
    icon: SecretKeyIcons[SecretKeyEnum.BOX],
    component: BoxKey
  },

  {
    value: SecretKeyEnum.MICROSOFT_SHARE_POINT,
    name: SecretKeyName[SecretKeyEnum.MICROSOFT_SHARE_POINT],
    icon: SecretKeyIcons[SecretKeyEnum.MICROSOFT_SHARE_POINT],
    component: MicrosoftSharePoint
  },
  {
    value: SecretKeyEnum.MICROSOFT_ONE_DRIVE,
    name: SecretKeyName[SecretKeyEnum.MICROSOFT_ONE_DRIVE],
    icon: SecretKeyIcons[SecretKeyEnum.MICROSOFT_ONE_DRIVE],
    component: MicrosoftOneDrive
  }
];

type IPayloadType = {
  name: string;
  key: string;
  key_type: string;
};

export default function CreateSecretKeyModal({ isOpen, onClose, loadSecretKeys }: any) {
  const [keyType, setKeyType] = useState<any>();

  const keyTypeName = SecretKeyName[keyType];

  const handleKeySubmit = async (payload: IPayloadType) => {
    await UserProfile.createUserSecrets(payload);

    setKeyType(null);
    loadSecretKeys();
    onClose();
  };

  const renderSecretKeyForm = () => {
    if (!keyType) return null;

    const readerOption = SecretOptions.find((obj) => obj.value == keyType);

    if (!readerOption) {
      return null;
    }

    const SelectedComponent = readerOption.component;

    return (
      <SelectedComponent
        handleKeySubmit={handleKeySubmit}
        keyType={keyType}
        keyTypeName={keyTypeName}
      />
    );
  };

  return (
    <CustomModal title="API key" isOpen={isOpen} onClose={onClose}>
      <div className="select-model">
        <div className="flex gap-6 flex-wrap">
          {SecretOptions.map((model) => (
            <div
              key={model.value}
              onClick={() => {
                setKeyType(model.value);
              }}
              className="border-[1px] border-[#252D38] bg-[#191F28] w-[161px] h-[110px] flex items-center flex-start p-6 rounded-lg cursor-pointer"
            >
              <div>
                <div className="mb-4">
                  <img width="20" src={model.icon} alt="Icon" />
                </div>
                {model.name}
              </div>
            </div>
          ))}
        </div>
        {keyType && (
          <ModalSecretForm onBack={() => setKeyType(null)} headerName={keyTypeName}>
            {renderSecretKeyForm()}
          </ModalSecretForm>
        )}
      </div>
    </CustomModal>
  );
}
