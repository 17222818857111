import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Subscription } from '../../apis/subscription';
import { useQuery } from 'core/hooks';

const PaymentSuccess = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const session_id = query.get('session_id');

  const verifySubscription = async (session_id: string) => {
    const data = await Subscription.verifySubscriptionSession({ session_id });
    if (data) {
      navigate('/agents');
    }
  };

  useEffect(() => {
    if (session_id) {
      verifySubscription(session_id);
    }
  }, [session_id]);

  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <h1 className="text-4xl font-bold mb-4">Subscription In Progress...</h1>
        <p className="text-lg">We are processing your payment</p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
