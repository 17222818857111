import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

type IFormInput = {
  name: string;
  client_id: string;
  client_secret: string;
  enterprise_id: string;
};

export default function BoxKey({ handleKeySubmit, keyType }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const { client_id, client_secret, enterprise_id } = data;

    const key = JSON.stringify({ client_id, client_secret, enterprise_id });

    const payload = {
      key_type: keyType,
      name: data.name,
      key: key
    };

    handleKeySubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            Key Name
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('name', { required: true })}
          />
        </div>
        {errors.name && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            Box Client Id
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('client_id', { required: true })}
          />
        </div>
        {errors.client_id && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            Box Client Secret
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('client_secret', { required: true })}
          />
        </div>
        {errors.client_secret && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            Box Enterprise Id
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('enterprise_id', { required: true })}
          />
        </div>
        {errors.enterprise_id && <span className="text-red-400">This field is required</span>}
      </div>
      <input type="submit" className="submitBtn w-full mt-8 cursor-pointer" />
    </form>
  );
}
