import { IWebSocketEventData, WS_EVENT_TYPES } from './types';

import { CustomerChatResolver } from './resolver/customerChat';

const resolverFunctionMap = {
  [WS_EVENT_TYPES.CUSTOMER_CHAT_CREATE_MESSAGE]: CustomerChatResolver.createMessage,
  [WS_EVENT_TYPES.CUSTOMER_CHAT_EDIT_MESSAGE]: CustomerChatResolver.editMessage,
  [WS_EVENT_TYPES.CUSTOMER_CHAT_DELETE_MESSAGE]: CustomerChatResolver.deleteMessage,
  [WS_EVENT_TYPES.CUSTOMER_CHAT_UPDATE_CUSTOMER_USER]: CustomerChatResolver.updateCustomerUser
};

const eventHandler = (event: any) => {
  let eventData: IWebSocketEventData;

  try {
    eventData = JSON.parse(event.data);
  } catch (error) {
    console.log(`error=${error}`);
    return;
  }

  const resolverFunction = resolverFunctionMap[eventData.type];

  if (resolverFunction) {
    resolverFunction(eventData);
  }
};

export default eventHandler;
