import { useEffect } from 'react';
import React from 'react-redux';

import { UserProfile } from '../apis/Profile';
import { getAccessToken } from 'core/helpers/token';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'components/Layout/Layout';

const Home = () => {
  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    const data = await UserProfile.userInfo();

    if (data !== null) {
      if (data.has_project || data.subscription) {
        navigate('/agents');
      } else {
        navigate('/onboarding');
      }
    }
  };

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken !== '') {
      fetchUserInfo();
    }
  }, []);

  return (
    <Layout>
      <div className="bg-[#191F28]"></div>
    </Layout>
  );
};

export default Home;
