import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ReaderEnum, ingestConfig } from 'core/helpers/ingest';

import IngestUploadBtn from '../IngestUploadBtn';

type IFormInput = {
  secret_id: string;
  sharepoint_site_name: string;
  sharepoint_folder_path: string;
  link: string;
};

export default function IngestSharePoint({ handleOnSubmit, onClose, secretKeys }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IFormInput>();

  useEffect(() => {
    if (secretKeys?.length) {
      setValue('secret_id', secretKeys[0].id, { shouldDirty: true });
    }
  }, [secretKeys]);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const payload = {
      reader_type: ReaderEnum.MICROSOFT_SHARE_POINT,
      secret_id: data.secret_id,
      sharepoint: {
        sharepoint_site_name: data.sharepoint_site_name,
        sharepoint_folder_path: data.sharepoint_folder_path,
        link: data.link
      },
      config: ingestConfig
    };

    handleOnSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">SharePoint</div>
      </div>
      <div className="mb-4">
        <label className="text-[13px] block font-normal mb-2 leading-4">
          SharePoint Secret Key
        </label>
        <select
          {...register('secret_id', { required: true })}
          className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
        >
          {secretKeys.map((secretKey: any) => {
            return (
              <option
                key={`secret-key-option-${secretKey.id}`}
                value={secretKey.id}
                className="custom-option"
              >
                {secretKey.name}
              </option>
            );
          })}
        </select>
        {errors.secret_id && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            SharePoint Site Name
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('sharepoint_site_name', { required: true })}
          />
        </div>
        {errors.sharepoint_site_name && (
          <span className="text-red-400">This field is required</span>
        )}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            SharePoint Folder Path
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('sharepoint_folder_path', { required: true })}
          />
        </div>
        {errors.sharepoint_folder_path && (
          <span className="text-red-400">This field is required</span>
        )}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            SharePoint Link
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('link', { required: true })}
            type="url"
          />
        </div>
        {errors.link && <span className="text-red-400">This field is required</span>}
      </div>

      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
