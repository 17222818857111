import React from 'react';

interface SpinnerLoaderProps {
  children: React.ReactNode;
  loading: boolean;
  icon_size?: number;
  className?: string;
  isBlur?: boolean;
}

export default function SpinnerLoader({
  children,
  loading,
  className,
  icon_size = 25,
  isBlur = true
}: SpinnerLoaderProps) {
  const blurClasses = isBlur ? 'bg-background/50 backdrop-blur-[1px]' : '';
  return (
    <div className={`relative ${className}`}>
      {children}
      {loading && (
        <div className={`absolute inset-0 flex items-center justify-center ${blurClasses}`}>
          <img src="/icons/loading-spinner.svg" className="text-primary" width={icon_size} />
        </div>
      )}
    </div>
  );
}
