import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import CustomModal from 'components/Modal/CustomModal';

type IFormInput = {
  is_published: boolean;
  name: string;
  site_domain: string;
  description: string | null;
  manychat_token: string | null;
};

const ChatWidgetForm = ({ title, isOpen, onClose, handleFormSubmit, defaultValue = null }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>({ defaultValues: defaultValue });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const payload: any = { ...data };

    if (defaultValue?.manychat_token != data?.manychat_token) {
      payload.manychat_token = data.manychat_token;
    } else {
      delete payload.manychat_token;
    }

    handleFormSubmit(payload);
  };

  return (
    <CustomModal title={title} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center rounded dark:hover:bg-gray-600">
          <label className="cursor-pointer p-1 w-full text-lg font-medium text-gray-200 rounded dark:text-gray-300">
            <input
              className="mr-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              type="checkbox"
              {...register('is_published', { required: false })}
            />
            Is Published
          </label>
        </div>
        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">Name</label>
          </div>
          <div className="relative">
            <input
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('name', { required: true })}
              type="text"
            />
          </div>
          {errors.name && <span className="text-red-400">This field is required</span>}
        </div>
        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
              Site Domain
            </label>
          </div>
          <div className="relative">
            <input
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('site_domain', { required: true })}
              type="url"
            />
          </div>
          {errors.site_domain && <span className="text-red-400">This field is required</span>}
        </div>
        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
              ManyChat Token
            </label>
          </div>
          <div className="relative">
            <input
              className="w-full bg-slate-700 border mt-[5px] text-white Input pr-10"
              {...register('manychat_token')}
              type="text"
            />
          </div>
        </div>

        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
              Description
            </label>
          </div>
          <div className="relative">
            <textarea
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('description', { required: false })}
            />
          </div>
        </div>

        <input type="submit" className="submitBtn w-full mt-8 cursor-pointer" />
      </form>
    </CustomModal>
  );
};

export default ChatWidgetForm;
