import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CustomerChatAPI } from 'apis/customerChat';

import ChatWidgetForm from './ChatWidgetForm';
import { ChatWidgetCard } from './ChatWidgetCard';
import { useSelector } from 'react-redux';
import { RootStateType } from 'store/store';
import { DEFAULT_LIMIT } from 'core/constants';

const ChatWidget: React.FC = () => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const chatWidgets = useSelector((state: RootStateType) => state.customerChat.chatWidgets);
  const { projectUid } = useParams();

  const getChatWidgets = (projectUid: string) => {
    CustomerChatAPI.getChatWidgets({ project_uid: projectUid, limit: DEFAULT_LIMIT });
  };

  useEffect(() => {
    if (projectUid) {
      getChatWidgets(projectUid);
    }
  }, [projectUid]);

  const handleCreate = async (payload: any) => {
    if (!projectUid) {
      console.log('Invalid agent id');
      return;
    }
    const data = await CustomerChatAPI.addChatWidget(projectUid, payload);
    if (data) {
      setCreateModalVisible(false);
      getChatWidgets(projectUid);
    }
  };

  return (
    <div className="w-[1000px]">
      <div className="flex items-center justify-between mb-6">
        <div>Chat Widget</div>
        <div className="file-upload-box-inner text-center block relative">
          <button
            onClick={() => {
              setCreateModalVisible(true);
            }}
            className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
          >
            Add New
          </button>
        </div>
      </div>
      <div className="p-8 border-[1px] border-solid border-[#222831] rounded-[16px] mb-12">
        {chatWidgets.map((chatWidget: any) => {
          return (
            <ChatWidgetCard
              key={`chat-widget-card::${chatWidget.id}`}
              projectUid={projectUid}
              chatWidget={chatWidget}
            />
          );
        })}
      </div>
      {createModalVisible && (
        <ChatWidgetForm
          title="Create Widget"
          isOpen={createModalVisible}
          onClose={() => setCreateModalVisible(false)}
          handleFormSubmit={handleCreate}
        />
      )}
    </div>
  );
};

export default ChatWidget;
