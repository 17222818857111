import React, { useEffect, useState } from 'react';

import { UserProfile } from 'apis/Profile';
import { toHumanReadableDate } from 'core/helpers';

import CreateSecretKeyModal from './SecretKey/CreateSecretKeyModal';
import { DEFAULT_LIMIT } from 'core/constants';

function SecretKey() {
  const [isCreteSecretOpen, setIsCreteSecretOpen] = useState(false);
  const [secretKeys, setSecretKeys] = useState<any[]>([]);

  const loadSecretKeys = async () => {
    const data = await UserProfile.getUserSecrets({ limit: DEFAULT_LIMIT });
    const keys = data?.results || [];
    setSecretKeys(keys);
  };

  useEffect(() => {
    if (!secretKeys?.length) {
      loadSecretKeys();
    }
  }, []);

  const handleDelete = async (secretId: number) => {
    if (window.confirm('Are you sure want to delete?')) {
      await UserProfile.deleteUserSecrets(secretId);
      loadSecretKeys();
    }
  };

  return (
    <div className="pl-4 pt-6 w-[1000px]">
      <div className="flex items-center justify-between pb-6">
        <h2 className="text-[24px] font-semibold">API Key</h2>
        <div className="">
          <button
            onClick={() => setIsCreteSecretOpen(true)}
            className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
          >
            <img width="16" src="/settings/user-icon.svg" alt="Close icon" />
            Add
          </button>
        </div>
      </div>
      <div className="py-6 pb-[10px] border-[1px] border-solid border-[#222831] rounded-[32px]">
        <table className="table-auto w-full">
          <thead>
            <tr className="border-b border-solid border-b-[#222831]">
              <th className="text-left text-[#4E637B] text-sm font-medium pl-6 pb-6">Name</th>

              <th className="text-[#4E637B] text-sm font-medium text-center pb-6">Type</th>
              <th className="text-[#4E637B] text-sm font-medium text-center pb-6">Created At</th>
              <th className="text-[#4E637B] text-sm font-medium text-center pr-6 pb-6">Action</th>
            </tr>
          </thead>
          <tbody>
            {secretKeys.map((obj: any) => {
              return (
                <tr
                  key={`secret-key-${obj.id}`}
                  className="border-b border-solid border-b-[#222831] last-of-type:border-0"
                >
                  <td className="py-3 text-left pl-6">
                    <span className="text-[#F6F7F9] text-[16px] font-normal">{obj.name}</span>
                  </td>
                  <td className="py-3 text-center">
                    <span className="text-[#F6F7F9] text-[16px] font-normal">{obj.key_type}</span>
                  </td>
                  <td className="py-3 text-center w-[200px]">
                    <span className="text-[#F6F7F9] text-[16px] font-normal">
                      {toHumanReadableDate(obj.created_at)}
                    </span>
                  </td>
                  <td className="py-3 pr-6">
                    <div className="flex items-cneter justify-center">
                      <img
                        onClick={() => handleDelete(obj.id)}
                        className="cursor-pointer"
                        src="/icons/delete-icon-gray.svg"
                        alt="Delete"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CreateSecretKeyModal
        isOpen={isCreteSecretOpen}
        onClose={() => setIsCreteSecretOpen(false)}
        loadSecretKeys={loadSecretKeys}
      />
    </div>
  );
}

export default SecretKey;
