import { createSlice } from '@reduxjs/toolkit';

export interface ILocalData {
  isUploadingIngest: boolean;
}

const initialState: ILocalData = {
  isUploadingIngest: false
};

const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    setUploadingIngest: (state, action) => {
      state.isUploadingIngest = action.payload.isUploadingIngest;
    }
  }
});

export const { setUploadingIngest } = localSlice.actions;
export default localSlice.reducer;
