import React, { useState } from 'react';

import { Auth } from '../apis/Auth';
import CustomModal from './Modal/CustomModal';

const DEFAULT_VALUE = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

function ChangePasswordModal({ isOpen, onClose }: any) {
  const [formData, setFormData] = useState(DEFAULT_VALUE);
  const [errors, setErrors] = useState(DEFAULT_VALUE);
  const [passwordVisibility, setPasswordVisibility] = useState<any>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  function onPasswordVisibilityToggle(key: string) {
    setPasswordVisibility({
      ...passwordVisibility,
      [key]: !passwordVisibility[key]
    });
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formErrors: any = {};
    let hasErrors = false;

    const { oldPassword, newPassword, confirmPassword } = formData;

    if (!oldPassword.trim()) {
      formErrors.oldPassword = 'Password is required';
      hasErrors = true;
    }

    if (!newPassword.trim()) {
      formErrors.newPassword = 'Password is required';
      hasErrors = true;
    }

    if (!confirmPassword.trim()) {
      formErrors.confirmPassword = 'Confirm Password is required';
      hasErrors = true;
    }

    if (!hasErrors) {
      if (newPassword.trim() !== confirmPassword.trim()) {
        formErrors.confirmPassword = 'Password Unmatched';
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setErrors(formErrors);
      return;
    }

    setErrors(formErrors);

    const payload = {
      old_password: oldPassword,
      new_password: newPassword
    };

    const data = await Auth.changePassword(payload);
    if (data) {
      onClose(true);
    }
  };

  return (
    <CustomModal title="Change Password" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex flex-start">
            <label
              htmlFor="password"
              className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
            >
              Old Password:
            </label>
          </div>
          <div className="relative">
            <input
              value={formData.oldPassword}
              onChange={handleChange}
              type={passwordVisibility.oldPassword ? 'text' : 'password'}
              id="password"
              name="oldPassword"
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            />
            <img
              onClick={() => {
                onPasswordVisibilityToggle('oldPassword');
              }}
              className="absolute eyeIcon pointer "
              src="/auth/Icon.png"
              alt=""
            />
          </div>
          <div className="flex flex-start">
            {errors.oldPassword && (
              <div className="text-red-500 text-sm mt-1">{errors.oldPassword}</div>
            )}
          </div>
        </div>

        <div className="mb-4">
          <div className="flex flex-start">
            <label
              htmlFor="password"
              className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
            >
              New Password:
            </label>
          </div>
          <div className="relative">
            <input
              value={formData.newPassword}
              onChange={handleChange}
              type={passwordVisibility.newPassword ? 'text' : 'password'}
              id="password"
              name="newPassword"
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            />
            <img
              onClick={() => {
                onPasswordVisibilityToggle('newPassword');
              }}
              className="absolute eyeIcon pointer "
              src="/auth/Icon.png"
              alt=""
            />
          </div>
          <div className="flex flex-start">
            {errors.newPassword && (
              <div className="text-red-500 text-sm mt-1">{errors.newPassword}</div>
            )}
          </div>
        </div>
        <div className="mb-4">
          <div className="flex flex-start">
            <label
              htmlFor="password"
              className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
            >
              Confirm Password:
            </label>
          </div>
          <div className="relative">
            <input
              value={formData.confirmPassword}
              onChange={handleChange}
              type={passwordVisibility.confirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            />
            <img
              onClick={() => {
                onPasswordVisibilityToggle('confirmPassword');
              }}
              className="absolute eyeIcon pointer "
              src="/auth/Icon.png"
              alt=""
            />
          </div>
          <div className="flex flex-start">
            {errors.confirmPassword && (
              <div className="text-red-500 text-sm mt-1">{errors.confirmPassword}</div>
            )}
          </div>
        </div>
        <button type="submit" className="submitBtn w-full mt-8">
          Change Password
        </button>
      </form>
    </CustomModal>
  );
}

export default ChangePasswordModal;
