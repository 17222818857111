import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Ingest } from 'apis/Ingest';

import IngestModal from './IngestModal';
import IngestRow from './IngestRow';
import { DEFAULT_LIMIT } from 'core/constants';

function IngestBody() {
  const [ingestModalVisible, setIngestModalVisible] = useState(false);
  const [ingests, setIngests] = useState([]);
  const { projectUid } = useParams();

  useEffect(() => {
    getIngestedFiles();
  }, [ingestModalVisible]);
  const getIngestedFiles = async () => {
    const data = await Ingest.fileList(projectUid, { limit: DEFAULT_LIMIT });

    if (data) {
      setIngests(data?.results || []);
    }
  };

  return (
    <>
      <IngestModal isOpen={ingestModalVisible} onClose={() => setIngestModalVisible(false)} />
      {!ingestModalVisible && (
        <div className="w-[1000px]">
          <div className="flex items-center justify-between mb-6">
            <div>File List</div>
            <div className="file-upload-box-inner text-center block relative">
              {/* <input className='w-[125px] h-[48px] opacity-0	z-[9999] absolute right-0 left-0 mx-auto' name="" id="" type="file" /> */}
              <button
                onClick={() => {
                  setIngestModalVisible(true);
                }}
                className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
              >
                <img src="/icons/upload-icon.svg" alt="Ingest Knowledge" />
                Upload Knowledge
              </button>
            </div>
          </div>
          <div className="py-7 px-0 pb-[10px] border-[1px] border-solid border-[#222831]  rounded-[16px] mb-12">
            <table className="table-auto w-full">
              <thead>
                <tr className="border-b border-solid border-b-[#222831]">
                  <th className="text-center text-[#4E637B] text-sm font-medium w-[300px] pb-6">
                    File Name
                  </th>

                  <th className="text-[#4E637B] text-sm font-medium text-center pb-6">
                    Ingest Type
                  </th>
                  <th className="text-[#4E637B] text-sm font-medium text-center pb-6">Status</th>
                  <th className="text-[#4E637B] text-sm font-medium text-center pb-6">Added by</th>
                  <th className="text-[#4E637B] text-sm font-medium w-[200px] pb-6">Crated At</th>
                  {/* <th className="text-[#4E637B] text-sm font-medium">YY - MM - DD</th> */}
                  <th className="text-[#4E637B] text-sm font-medium pb-6">Action</th>
                </tr>
              </thead>
              <tbody>
                {ingests?.map((ingest: any) => {
                  return (
                    <IngestRow
                      key={`ingest-row-${ingest.id}`}
                      ingest={ingest}
                      getIngestedFiles={getIngestedFiles}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default IngestBody;
