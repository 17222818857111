import React from 'react';

import { IImageType } from '../types';
import { getFilePath } from '../core/helpers';

interface AvatarParams {
  image: IImageType | null;
  full_name?: string;
  size?: number;
  fontSize?: any;
  onRemove?: (a: string) => void;
  showCrossIcon?: boolean;
}

const ImageAvatar = ({
  image,
  onRemove,
  showCrossIcon = true,
  size = 150,
  fontSize,
  full_name
}: AvatarParams) => {
  const handleOnRemove = (e: any) => {
    e.preventDefault();
    if (onRemove) {
      onRemove(e);
    }
  };

  const getInitials = () => {
    const initials = full_name
      ?.split(' ')
      .map((word) => word[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();

    return initials;
  };

  const crossBtnMargin = Math.floor(size / 20);
  const crossBtnSize = Math.floor(size / 5);

  return (
    <div className="text-center relative inline-block">
      {image?.file_path ? (
        <img
          src={getFilePath(image?.file_path)}
          alt="Profile"
          className="rounded-full mx-auto w-32 h-32"
          style={{ height: size, width: size }}
        />
      ) : (
        <div
          className="mx-auto rounded-full bg-gray-300 flex items-center justify-center text-gray-700 font-bold"
          style={{ height: size, width: size }}
        >
          <p className={`text-${fontSize}`}>{getInitials()}</p>
        </div>
      )}

      {showCrossIcon && (
        <button
          className={`absolute top-0 right-0 bg-red-500 text-white rounded-full flex items-center justify-center focus:outline-none`}
          style={{
            height: crossBtnSize,
            width: crossBtnSize,
            marginTop: crossBtnMargin,
            marginRight: crossBtnMargin
          }}
          onClick={handleOnRemove}
        >
          &times;
        </button>
      )}
    </div>
  );
};

export default ImageAvatar;
