import './Onboarding.css';

import React, { useState } from 'react';

import { Upload } from '../../apis/Upload';
import { Layout } from '../../components/Layout/Layout';
import { Modal } from '../../components/OnboardingModal/Modal';
import { getFilePath } from '../../core/helpers/index';

const Onboarding = () => {
  const [step, setStep] = useState(1);

  const WelcomeMessage = () => {
    return (
      <div>
        <div>
          <img src="/onboarding/welcome-image.svg" className="inline-block mb-8" />
          <h3 className="text-2xl	font-semibold text-white mb-4">
            Welcome to <span className="text-[#5F6FFF]">Neu Wark!</span>
          </h3>
          <p className="mb-12">
            Ensuring you get answers to your questions within minutes simply by asking. You&apos;ll
            have control over setting up your data resources, empowering you to tailor your
            experience to your needs.
          </p>
        </div>
        <button
          onClick={() => {
            setStep(2);
          }}
          className="bg-[#5F6FFF] text-white px-8 py-5 rounded-xl text-base	font-semibold"
        >
          Start your journey
        </button>
      </div>
    );
  };

  const UploadImage = () => {
    const [image, setImage] = useState<any>(null);
    // const profile = useSelector((state: any) => state.login.profile);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleUpload = async (e: any) => {
      const file = e.target.files?.[0];
      if (!file) return;

      setUploadProgress(0);

      const data = await Upload.uploadImage(file, setUploadProgress);
      if (data) {
        setImage({
          name: data.name,
          id: data.id,
          path: data?.file_path
        });
      }

      setUploadProgress(0);
    };
    return (
      <div>
        <div className="rounded-[10px]">
          {!image && !uploadProgress && (
            <div className="text-center block">
              <img src="/onboarding/drop-image.svg" className="inline-block mb-8" />
              <h3 className="text-2xl	font-semibold text-white mb-4">Upload a Profile Image</h3>
              <p className="mb-8 text-[#8297AE] text-[13px]">
                Upload a square sized photo for best result & Maximum image size limit should be 200
                KB (JPG, PNG, JPEG).
              </p>
              <div className="relative">
                <input
                  className="w-[150px] h-[60px] opacity-0	z-[9999] absolute right-0 left-0 mx-auto"
                  name=""
                  id=""
                  type="file"
                  onChange={(e) => handleUpload(e)}
                />
                <button className="file-upload-btn text-white bg-[#5F6FFF] rounded-[8px] py-[12px] pl-[20px] pr-[16px] inline-flex items-center justify-center gap-[8px] mb-4 text-[14px] font-medium border-[1px] border-[#5F6FFF] border-solid">
                  <img width="10" src="/onboarding/download-icon.svg" alt="Upload Icon" />
                  Upload Image
                </button>
              </div>
            </div>
          )}

          {uploadProgress > 0 && (
            <div className="mt-4 mb-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <p className="mt-2 text-sm text-gray-600">Uploading: {uploadProgress}%</p>
            </div>
          )}

          {image && (
            <div className="mx-auto border-[1px] border-dashed	rounded-2xl	border-[#323C48] mb-8 pt-8">
              <div className="w-[110px] h-[110px] rounded-full border-[2px] border-solid border-[#323C48] flex items-center justify-center mx-auto mb-8">
                <img
                  className="inline-block p-[2px] rounded-full overflow-hidden"
                  src={getFilePath(image.path)}
                  alt=""
                />
                {/* <img className='inline-block p-[2px] rounded-full overflow-hidden' width="96" src="/onboarding/image.svg" alt="Upload Image" /> */}
              </div>
              <h3 className="text-2xl	font-semibold text-white mb-20">Image Uploaded</h3>
            </div>
          )}
        </div>

        <div className="flex items-center gap-[16px] mt-6">
          <button
            onClick={() => {
              window.location.href = '/';
            }}
            className="bg-[#323C48] hover:bg-[#5F6FFF] hover:text-white text-[#8297AE] px-8 py-5 rounded-xl text-base	font-semibold basis-1/2"
          >
            Skip
          </button>
          <button
            onClick={() => {
              if (image) window.location.href = '/subscriptions/packages';
            }}
            className="bg-[#323C48] hover:bg-[#5F6FFF] hover:text-white text-[#8297AE] px-8 py-5 rounded-xl text-base	font-semibold basis-1/2"
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  return (
    <Layout>
      <div className="flex justify-center items-center h-screen">
        <Modal>
          {/* <WelcomeMessage /> */}
          {step == 1 && <WelcomeMessage />}
          {step == 2 && <UploadImage />}
        </Modal>
      </div>
    </Layout>
  );
};

export default Onboarding;
