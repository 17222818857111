import { Dispatch } from '@reduxjs/toolkit';

import { API_URLS, buildUrl } from 'core/endpoints';
import {
  setChatWidget,
  setCustomers,
  setCustomerMessages,
  addMessage,
  updateCustomerUser,
  setChatWidgets
} from 'store/reducers/customerChatSlice';
import { API, toastApiError } from './api';
import store from 'store/store';

export class CustomerChatAPI {
  static async addChatWidget(projectUid: string, agentData: any) {
    try {
      const url = buildUrl(API_URLS.chatWidgets, { projectUid });
      const res = await API.post(url, agentData);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async getChatWidgets(queryParams: any = {}) {
    const url = buildUrl(API_URLS.allChatWidgets, null, queryParams);

    try {
      const res = await API.get(url);
      store.dispatch(setChatWidgets(res.data));
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async getChatWidgetDetails(dispatch: Dispatch, projectUid: string, chatWidgetId: string) {
    const url = buildUrl(API_URLS.chatWidgetDetails, { projectUid, chatWidgetId });
    try {
      const res = await API.get(url);
      dispatch(setChatWidget(res.data));
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async editChatWidgets(projectUid: string, chatWidgetId: number, payload: any) {
    const url = buildUrl(API_URLS.chatWidgetDetails, { projectUid, chatWidgetId });
    try {
      const res = await API.put(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async toggleGlobalManualMessage(projectUid: string, chatWidgetId: number, payload: any) {
    const url = buildUrl(API_URLS.toggleGlobalManualMessage, { projectUid, chatWidgetId });
    try {
      const res = await API.put(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async deleteChatWidgets(projectUid: string, chatWidgetId: string) {
    const url = buildUrl(API_URLS.chatWidgetDetails, { projectUid, chatWidgetId });
    try {
      const res = await API.delete(url);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getCustomers(
    dispatch: Dispatch,
    projectUid: string,
    chatWidgetId: string,
    queryParams: any = {}
  ) {
    const url = buildUrl(API_URLS.customers, { projectUid, chatWidgetId }, queryParams);

    try {
      const res = await API.get(url);
      dispatch(setCustomers(res.data));

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async updateCustomerUser(
    dispatch: Dispatch,
    projectUid: string,
    chatWidgetId: string,
    customerId: string,
    payload: any
  ) {
    const url = buildUrl(API_URLS.customerDetails, { projectUid, chatWidgetId, customerId });

    try {
      const res = await API.patch(url, payload);
      dispatch(updateCustomerUser(res.data));

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async getMessages(
    dispatch: Dispatch,
    projectUid: string,
    chatWidgetId: string,
    customerId: string,
    queryParams: any = {}
  ) {
    const url = buildUrl(
      API_URLS.customerMessages,
      { projectUid, chatWidgetId, customerId },
      queryParams
    );

    try {
      const res = await API.get(url);
      const data = { ...res.data, customerId };
      dispatch(setCustomerMessages(data));

      return data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async sendMessage(
    dispatch: Dispatch,
    projectUid: string,
    chatWidgetId: string,
    customerId: string,
    payload: any
  ) {
    const url = buildUrl(API_URLS.customerMessages, { projectUid, chatWidgetId, customerId });

    try {
      const res = await API.post(url, payload);
      const data = { ...res.data, customerId };
      dispatch(addMessage(data));

      return data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
  static async generateMessage(
    projectUid: string,
    chatWidgetId: string,
    customerId: string,
    payload: any
  ) {
    const url = buildUrl(API_URLS.generateCustomerMessages, {
      projectUid,
      chatWidgetId,
      customerId
    });

    try {
      const res = await API.post(url, payload);
      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
