import axios, { AxiosError } from 'axios';

import { BASE_API_URL } from 'core/constants';
import { API_URLS } from 'core/endpoints';

import {
  getAccessToken,
  getRefreshToken,
  willTokenExpiredUnderNMinutes,
  removeTokens,
  updateAccessToken
} from 'core/helpers/token';
import { toast } from 'react-toastify';

const DEFAULT_TIMEOUT = 1000 * 60 * 3; // 3 minutes

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
  'local-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
};

const getAuthorizationString = (token: string) => `Bearer ${token}`;

const BaseAxios = axios.create({
  baseURL: BASE_API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: { ...COMMON_HEADERS }
});

const API = axios.create({
  baseURL: BASE_API_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: { ...COMMON_HEADERS }
});

API.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = getAuthorizationString(token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers: any = [];

const subscribeTokenRefresh = (callback: any) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (accessToken: string) => {
  refreshSubscribers.map((callback: any) => callback(accessToken));
};

const refreshToken = async () => {
  const payload = { refresh_token: getRefreshToken() };
  try {
    const res = await axios.post(API_URLS.refreshToken, payload);
    updateAccessToken(res.data?.access_token);
    return res.data?.access_token;
  } catch (error) {
    removeTokens();
    window.location.href = '/logout';
    return null;
  }
};

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const accessToken = await refreshToken();
          isRefreshing = false;
          onRefreshed(accessToken);
          refreshSubscribers = [];
        } catch (err) {
          isRefreshing = false;
          return Promise.reject(err);
        }
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((accessToken: string) => {
          // replace the expired accessToken and retry the original request
          originalRequest.headers['Authorization'] = getAuthorizationString(accessToken);
          resolve(API(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);

const getAccessTokenThatMinValidForNMinutes = async (minutes: number) => {
  let accessToken = getAccessToken();

  if (willTokenExpiredUnderNMinutes(accessToken, minutes)) {
    accessToken = await refreshToken();
  }

  return accessToken;
};

export function getServerErrorMessage(err: AxiosError) {
  const status = err?.response?.status || 400;
  const responseData: any = err.response?.data;

  let message: string | null = null;

  if (status == 422) {
    message = 'Data is not valid. Contact with us!';
  } else if (status >= 400 && status < 500) {
    message = responseData?.detail;
  } else if (status > 500) {
    message = 'Something is wrong with the server. Try later or contact us!';
  }

  if (status == 401) message = null;

  return { status, message };
}

export function toastApiError(err: AxiosError | any, defaultMessage: string | null = null) {
  const { message } = getServerErrorMessage(err);

  if (message || defaultMessage) {
    toast.error(message || defaultMessage);
  }
}

export async function getStreamHeaders() {
  const token_padding_minutes = 5;
  const accessToken = await getAccessTokenThatMinValidForNMinutes(token_padding_minutes);
  const headers = {
    Accept: 'text/event-stream',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    'local-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
  };
  return headers;
}

export { API, BaseAxios, getAccessTokenThatMinValidForNMinutes };
