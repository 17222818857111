import React from 'react';

interface IProps {
  question: any;
}

function Question({ question }: IProps) {
  return (
    <div className="py-[15px] text-[#D4DBE3] font-semibold text-[24px] mb-4 min-w-96">
      <p>{question}</p>
    </div>
  );
}

export default Question;
