import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Ingest } from 'apis/Ingest';
import IngestUploadBtn from '../IngestUploadBtn';
import { useDispatch } from 'react-redux';
import { setUploadingIngest } from 'store/reducers/localSlice';

export default function IngestFile({ onClose, setReaderType }: any) {
  const { projectUid } = useParams();
  const dispatch = useDispatch();

  const handleFileUploadIngest = async (e) => {
    e.preventDefault();
    dispatch(setUploadingIngest({ isUploadingIngest: true }));

    const data = await Ingest.fileUpload(file, projectUid);

    dispatch(setUploadingIngest({ isUploadingIngest: false }));

    if (data !== null) {
      setReaderType(null);
      onClose(false);

      toast.success('Ingest uploaded successfully. We are processing your data');
    }
  };
  const [file, setFile] = useState<any>(null);

  return (
    <form onSubmit={handleFileUploadIngest}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">File</div>
      </div>
      <div>
        <label className="text-[13px] block font-normal mb-2 leading-4">Upload File</label>

        <div className="text-center file-upload-box border-[1px] border-dashed rounded-2xl border-[#323C48] pt-12 pb-3">
          <div className="relative">
            <input
              className="w-[150px] h-[60px] opacity-0	z-[9999] absolute right-0 left-0 mx-auto"
              name=""
              id=""
              type="file"
              onChange={(e: any) => {
                setFile(e.target.files[0]);
              }}
            />
            {file && <div>{file.name}</div>}
            {!file && (
              <button className="file-upload-btn text-white bg-[#5F6FFF] rounded-[8px] py-[12px] pl-[20px] pr-[16px] inline-flex items-center justify-center gap-[8px] mb-4 text-[14px] font-medium border-[1px] border-[#5F6FFF] border-solid">
                <img width="10" src="/icons/upload-icon.svg" alt="Upload Icon" />
                Upload File
              </button>
            )}
          </div>
          <p className="text-[#8297AE] text-[13px]">
            Please upload your file. Accepted file types include PDF, XML, Word, etc. Maximum <br />
            file size is 20 MB.
          </p>
        </div>
      </div>
      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
