export enum WS_EVENT_TYPES {
  CUSTOMER_CHAT_CREATE_MESSAGE = 'CUSTOMER_CHAT_CREATE_MESSAGE',
  CUSTOMER_CHAT_EDIT_MESSAGE = 'CUSTOMER_CHAT_EDIT_MESSAGE',
  CUSTOMER_CHAT_DELETE_MESSAGE = 'CUSTOMER_CHAT_DELETE_MESSAGE',
  CUSTOMER_CHAT_UPDATE_CUSTOMER_USER = 'CUSTOMER_CHAT_UPDATE_CUSTOMER_USER'
}

export interface IWebSocketEventData {
  type: WS_EVENT_TYPES;
  payload: any;
  sender: any;
}
